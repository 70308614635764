import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './home.css';
import SectionBanner from './component/Sections/SectionBanner';
import SectionScroller from './component/Sections/SectionScroller';
import SectionWhatDoesPaigeDo from './component/Sections/SectionWhatDoesPaigeDo';
import SectionHowItWorks from './component/Sections/SectionHowItWorks';
import SectionSEOStrategy from './component/Sections/SectionSEOStrategy';
import SectionWhoCanUse from './component/Sections/SectionWhoCanUse';
import SectionSampleContent from './component/Sections/SectionSampleContent';
import SectionTestimonial from './component/Sections/SectionTestimonial';

const Home = () => {
  return (
    <>
      <SectionBanner />
      <SectionScroller />
      <SectionWhatDoesPaigeDo />
      <SectionHowItWorks />
      <SectionSEOStrategy />
      <SectionWhoCanUse />
      <SectionSampleContent />
      {/* <SectionTestimonial /> */}
    </>
  );
};

export default Home;
