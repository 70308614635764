import { SET_SCHEDULE } from './scheduleConstants';

/**
 * Set current user
 */
export function setSchedule(payload) {
  return {
    type: SET_SCHEDULE,
    payload,
  };
}
