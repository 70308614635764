import React from 'react';
const SectionWhatDoesPaigeDo = () => (
  <section className="custom-objects-img-bg">
    <div className="container ">
      <div className="row py-5 justify-content-between row-paige-does">
        <div className="col-md-7 mt-5 pt-5 second-section">
          <h6 className="banner-subheading-cls">Featured</h6>
          <h2 className="h2-custom-cls">
            What Does <span> Paige</span> Do?
          </h2>
          <p className="mt-2 p-cls">
            Paige is an all-in-one SEO content automation tool. To start, she
            actively monitors your website to determine what type of content
            your site needs to rank higher on search engines and generate more
            leads.
          </p>
          <p className="mt-2 p-cls">
            After determining what content your site needs, Paige produces the
            content in an optimized format for search engines. Finally, she
            publishes this content directly to your website for search engines
            to start crawling and for customers to start finding.
          </p>
        </div>
        <div className="col-md-4 my-5  flex-column ">
          <div className="text-center">
            <img
              className="img-fluid robofly"
              src="/images/OBJECTS.png"
              alt=""
            />
            <div className="bg-blue">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default SectionWhatDoesPaigeDo;
