import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { TABS } from '../../../AppTabs';

const AccountTabSettingsRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(Object.values(TABS.SETTINGS.TABS)[0].path, { replace: true });
  }, []);

  return null;
};

export default AccountTabSettingsRedirect;
