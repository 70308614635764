import React, { Component, useState, useEffect } from 'react';
import _ from 'lodash';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, firebase } from '.';
import {
  useDocumentData,
  useCollectionData,
  useCollection,
} from 'react-firebase-hooks/firestore';
import { collection, doc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setUser } from './reducers/user/userActions';
import { setStrategy } from './reducers/strategy/strategyActions';
import { setPost } from './reducers/post/postActions';
import { setSchedule } from './reducers/schedule/scheduleActions';
import { setCurrentSubscription } from './reducers/subscriptions/subscriptionsActions';
import {
  clearProducts,
  setCurrentProducts,
} from './reducers/products/productsActions';

const AppReducer = () => {
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);
  const [userUID, setUserUID] = useState('a');
  useEffect(() => {
    if (!!user && _.isString(user.uid) && user.uid.length > 0) {
      if (user.uid !== userUID) {
        setUserUID(user.uid);
      }
    } else {
      setUserUID('a');
    }
  }, [user]);

  const [userValue, userValueLoading, userValueError] = useDocumentData(
    doc(firebase.firestore(), 'users', userUID),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [customerValue, customerValueLoading, customerValueError] =
    useDocumentData(doc(firebase.firestore(), 'customers', userUID), {
      snapshotListenOptions: { includeMetadataChanges: true },
    });
  useEffect(() => {
    dispatch(
      setUser({
        uid: user?.uid,
        customer_id: customerValue?.stripeId,
        stripeLink: customerValue?.stripeLink,
        ...userValue,
      })
    );
  }, [userValue, customerValue]);
  const [strategyValue, strategyValueLoading, strategyValueError] =
    useCollection(
      firebase
        .firestore()
        .collection(`strategy/${userUID}/months`)
        .orderBy('active_at', 'desc'),
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );
  useEffect(() => {
    dispatch(
      setStrategy(
        strategyValue
          ? strategyValue.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          : []
      )
    );
    // dispatch(setStrategy(strategyValue));
  }, [strategyValue, strategyValueLoading, strategyValueError]);
  const [postValue, postValueLoading, postValueError] = useCollection(
    firebase
      .firestore()
      .collection(`posts/${userUID}/posts`)
      .orderBy('created_at'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  useEffect(() => {
    dispatch(
      setPost(
        postValue
          ? postValue.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          : []
      )
    );
  }, [postValue, postValueLoading, postValueError]);
  const [scheduleValue, scheduleValueLoading, scheduleValueError] =
    useDocumentData(doc(firebase.firestore(), 'schedule', userUID), {
      snapshotListenOptions: { includeMetadataChanges: true },
    });
  useEffect(() => {
    dispatch(setSchedule(scheduleValue));
  }, [scheduleValue, scheduleValueLoading, scheduleValueError]);

  const [subscriptionValue, subscriptionValueLoading, subscriptionValueError] =
    useCollection(
      firebase
        .firestore()
        .collection('customers')
        .doc(userUID || 'a')
        .collection('subscriptions'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  useEffect(() => {
    let subscriptions =
      subscriptionValue?.docs.map((doc) => {
        return {
          id: doc.id,
          // ...doc.data(),
          item: doc.data().items[0],
          ..._.omit(doc.data(), ['prices', 'price', 'product']),
        };
      }) || [];
    let memberSince = subscriptions.reduce((minDate, obj) => {
      if (!minDate || obj?.current_period_start?.seconds < minDate) {
        return obj.current_period_start.seconds;
      }
      return minDate;
    }, null);
    dispatch(
      setCurrentSubscription({
        data: subscriptions,
        current: subscriptions.find((s) => s.status === 'active'),
        memberSince,
      })
    );
  }, [subscriptionValue]);

  const [productsValue, productsValueLoading, productsValueError] =
    useCollection(
      firebase.firestore().collection('products').where('active', '==', true),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  useEffect(() => {
    (async () => {
      if (productsValue?.size > 0) {
        const promises = [];

        productsValue?.forEach((doc) => {
          const docData = { id: doc.id, ...doc.data() };
          const subcollectionRef = firebase
            .firestore()
            .collection('products')
            .doc(doc.id)
            .collection('prices');

          const subcollectionPromise = subcollectionRef
            .get()
            .then((subcollectionSnapshot) => {
              const subcollectionData = [];

              subcollectionSnapshot.forEach((subDoc) => {
                subcollectionData.push({ id: subDoc.id, ...subDoc.data() });
              });

              // docData.prices = subcollectionData;
              if (
                _.isArray(subcollectionData) &&
                subcollectionData.length > 0
              ) {
                docData.price = subcollectionData[0];
                docData.amount = subcollectionData[0].unit_amount;
              }
              return docData;
            });

          promises.push(subcollectionPromise);
        });

        // Wait for all promises to resolve
        let products = await Promise.all(promises);
        dispatch(
          setCurrentProducts(products.sort((a, b) => a.amount - b.amount))
        );

        // const products = [];
        // productsValue?.docs.forEach((doc) => {
        //   const docData = doc.data();
        //   // Get the subcollection reference for each document
        //   const subcollectionRef = doc.ref.collection('prices');

        //   // Query the subcollection documents
        //   subcollectionRef.get().then((subcollectionSnapshot) => {
        //     const subcollectionData = [];

        //     subcollectionSnapshot.forEach((subDoc) => {
        //       // Add the subcollection data to an array
        //       subcollectionData.push(subDoc.data());
        //     });

        //     // Add the subcollection data as a field in the document values
        //     docData.prices = subcollectionData;

        //     // Add the document data to an array
        //     products.push(docData);
        //   });
        // });
        // dispatch(setCurrentProducts(products));
      } else {
        dispatch(clearProducts());
      }
    })();
  }, [productsValue]);

  return null;
};

export default AppReducer;
