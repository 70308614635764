import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import { Redirect, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
  InputGroup,
  FloatingLabel,
  Row,
  Col,
  Card,
} from 'react-bootstrap';

import './signup.css';
import { auth, firebase } from '../index.js';
import { toast } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';

const Login = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get('redirect');

  const handleSubmit = async (values) => {
    try {
      auth
        .signInWithEmailAndPassword(values.email, values.password)
        .then((userCredential) => {
          const user = userCredential.user;
          toast(
            `Logged in${
              _.isString(user.displayName) && user.displayName.length > 0
                ? ` as ${user.displayName}`
                : ''
            }`,
            { type: 'success' }
          );
        })
        .catch((error) => {
          console.error(error.code, error.message);
          toast(
            'Error logging in: ' +
              error.message.replace('Firebase:', '').trim(),
            { type: 'error' }
          );
          return false;
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Must be 6 characters or more'),
  });

  useEffect(() => {
    if (!!user) {
      if (_.isString(redirect) && redirect.length > 0) {
        navigate(redirect);
      } else {
        navigate('/account');
      }
    }
  }, [user, loading, error]);

  const formFields = {
    email: { type: 'email' },
    password: { type: 'password', autoComplete: 'current-password' },
  };

  const FloatingLabelInput = ({ label, name, ...rest }) => (
    <FloatingLabel label={label}>
      <Field name={name} id={name} {...rest} as={BootstrapForm.Control} />
    </FloatingLabel>
  );

  const renderSignUpByEmail = () => (
    <div id="page-login">
      <Container>
        <Row>
          <Col xs md={{ span: 4, offset: 4 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 10,
              }}
            >
              <div>Don't have an account?</div>
              <a href="/sign-up">Click to sign up</a>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'center',
                gap: 2,
                margin: '10px 0',
              }}
            >
              <div
                style={{ height: 0, border: '1px solid grey', flex: 1 }}
              ></div>
              <div style={{ flex: 0 }}>OR</div>
              <div
                style={{ height: 0, border: '1px solid grey', flex: 1 }}
              ></div>
            </div>
          </Col>
        </Row>{' '}
        <Row>
          <Col xs md={{ span: 4, offset: 4 }}>
            <div>Complete the form to log in</div>
            <Card>
              <Card.Body>
                <Formik
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    setSubmitting(true);
                    try {
                      (async (values, resetForm, setSubmitting) => {
                        setSubmitting(true);
                        let submitted = await handleSubmit(values);
                        setSubmitting(false);
                        resetForm();
                      })(values, resetForm, setSubmitting);
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={LoginSchema}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      {Object.entries(formFields).map(([key, value], index) => {
                        let name = value.name || key;
                        return (
                          <Row key={`${index}--${key}`} className="mb-3">
                            <BootstrapForm.Group as={Col}>
                              <FloatingLabelInput
                                label={
                                  value.label || _.startCase(_.toLower(name))
                                }
                                name={name}
                                type={value.type || 'text'}
                                placeholder={
                                  value.label || _.startCase(_.toLower(name))
                                }
                                autoComplete={value.autoComplete || name}
                                isInvalid={touched[name] && !!errors[name]}
                              />
                              {touched[name] && errors[name] && (
                                <BootstrapForm.Control.Feedback type="invalid">
                                  {errors[name]}
                                </BootstrapForm.Control.Feedback>
                              )}
                            </BootstrapForm.Group>
                          </Row>
                        );
                      })}
                      <Row className="mb-3">
                        <Col>
                          <Button type="submit" disabled={isSubmitting}>
                            Log In
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

  return <>{renderSignUpByEmail()}</>;
};

const mapStateToProps = (state) => {
  return {
    success: state.UserState.status,
  };
};

Login.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(Login);
