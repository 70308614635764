import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Redirect } from 'react-router-dom';
import _ from 'lodash';
import './App.css';
import './Nav.css';
import './Custom.css';
import './Pages.css';
import Home from './pages/Home';
import AdminLayout from './pages/component/layout/AdminLayout';
import PublicLayout from './pages/component/layout/PublicLayout';
import Signup from './pages/Signup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Account from './pages/Account';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firebase } from '.';
import AccountTabDashboard from './pages/component/AccountTabDashboard';
import AccountTabSiteDetails from './pages/component/AccountTabSite/AccountTabSiteDetails';
import AccountTabPlan from './pages/component/AccountTabPlan';
import AccountTabSettingsPosts from './pages/component/AccountTabSettings/AccountTabSettingsPosts';
import AccountTabSettings from './pages/component/AccountTabSettings/AccountTabSettings';
import AccountTabSettingsRedirect from './pages/component/AccountTabSettings/AccountTabSettingsRedirect';
import AccountTabSettingsBusiness from './pages/component/AccountTabSettings/AccountTabSettingsBusiness';
import AccountTabPostsRedirect from './pages/component/AccountTabPosts/AccountTabPostsRedirect';
import AccountTabPosts from './pages/component/AccountTabPosts/AccountTabPosts';
import AccountTabPostsReview from './pages/component/AccountTabPosts/AccountTabPostsReview';
import AccountTabPostsStrategy from './pages/component/AccountTabPosts/AccountTabPostsStrategy';
import AppReducer from './AppReducer';

import { TABS } from './AppTabs';
import ApprovePostForPublishFailed from './pages/ApprovePostForPublishFailed';
import Samples from './pages/Samples';

const App = () => {
  const [savingDetails, setSavingDetails] = useState(false);

  const [user, loading, error] = useAuthState(auth);

  const updateValue = async ({ value = null, name = null } = {}) => {
    if (
      !!name &&
      _.isString(name) &&
      name.length > 0 &&
      !!user?.uid &&
      _.isString(user?.uid) &&
      user?.uid.length > 0
    ) {
      let updates = {};
      if (_.isNil(value) || value === '') {
        value = firebase.firestore.FieldValue.delete();
      }
      updates[name] = value;
      if (_.isFunction(setSavingDetails)) {
        setSavingDetails(true);
      }
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .update(updates);
      if (_.isFunction(setSavingDetails)) {
        setSavingDetails(false);
        toast(
          `${_.startCase(
            _.toLower((name || 'Value').replaceAll('_', ' '))
          )} updated`,
          { type: 'success' }
        );
      }
    }
  };
  return (
    <>
      <AppReducer />
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PublicLayout>
                <Home />
              </PublicLayout>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <PublicLayout>
                <Login />
              </PublicLayout>
            }
          />
          <Route
            exact
            path="/logout"
            element={
              <PublicLayout>
                <Logout />
              </PublicLayout>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <PublicLayout>
                <Signup />
              </PublicLayout>
            }
          />
          <Route
            exact
            path="/sign-up"
            element={
              <PublicLayout>
                <Signup />
              </PublicLayout>
            }
          />
          <Route
            exact
            path="/approve-publish-post-failed"
            element={
              <PublicLayout>
                <ApprovePostForPublishFailed />
              </PublicLayout>
            }
          />
          <Route
            exact
            path="/samples/:name"
            element={
              <PublicLayout>
                <Samples />
              </PublicLayout>
            }
          />
          <Route exact path="/account" element={<AccountTabPostsRedirect />} />
          {/* <Route
            exact
            path={TABS.DASHBOARD.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.DASHBOARD.label}>
                  <AccountTabDashboard />
                </Account>
              </AdminLayout>
            }
          /> */}
          <Route
            exact
            path={TABS.POSTS.path}
            element={<AccountTabPostsRedirect />}
          />
          <Route
            exact
            path={TABS.POSTS.TABS.REVIEW.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.POSTS.label}>
                  <AccountTabPosts currentTab={TABS.POSTS.TABS.REVIEW.label}>
                    <AccountTabPostsReview
                      currentPage={TABS.POSTS.TABS.REVIEW.label}
                    />
                  </AccountTabPosts>
                </Account>
              </AdminLayout>
            }
          />
          <Route
            exact
            path={TABS.POSTS.TABS.STRATEGY.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.POSTS.label}>
                  <AccountTabPosts
                    updateValue={updateValue}
                    currentTab={TABS.POSTS.TABS.STRATEGY.label}
                  >
                    <AccountTabPostsStrategy
                      currentPage={TABS.POSTS.TABS.STRATEGY.label}
                    />
                  </AccountTabPosts>
                </Account>
              </AdminLayout>
            }
          />
          <Route
            exact
            path={TABS.SETTINGS.path}
            element={<AccountTabSettingsRedirect />}
          />
          <Route
            exact
            path={TABS.SETTINGS.TABS.POSTS.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.SETTINGS.label}>
                  <AccountTabSettings
                    updateValue={updateValue}
                    currentTab={TABS.SETTINGS.TABS.POSTS.label}
                  >
                    <AccountTabSettingsPosts
                      currentPage={TABS.SETTINGS.TABS.POSTS.label}
                      updateValue={updateValue}
                    />
                  </AccountTabSettings>
                </Account>
              </AdminLayout>
            }
          />
          <Route
            exact
            path={TABS.SETTINGS.TABS.BUSINESS.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.SETTINGS.label}>
                  <AccountTabSettings
                    updateValue={updateValue}
                    currentTab={TABS.SETTINGS.TABS.BUSINESS.label}
                  >
                    <AccountTabSettingsBusiness
                      currentPage={TABS.SETTINGS.TABS.BUSINESS.label}
                      updateValue={updateValue}
                    />
                  </AccountTabSettings>
                </Account>
              </AdminLayout>
            }
          />
          <Route
            exact
            path={TABS.SETTINGS.TABS.SITE.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.SETTINGS.label}>
                  <AccountTabSettings
                    updateValue={updateValue}
                    currentTab={TABS.SETTINGS.TABS.SITE.label}
                  >
                    <AccountTabSiteDetails updateValue={updateValue} />
                  </AccountTabSettings>
                </Account>
              </AdminLayout>
            }
          />
          {/* <Route
            exact
            path={TABS.SETTINGS.TABS.PROFILE.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.SETTINGS.label}>
                  <AccountTabSettings
                    updateValue={updateValue}
                    currentTab={TABS.SETTINGS.TABS.PROFILE.label}
                  >
                    <AccountTabPlan />
                  </AccountTabSettings>
                </Account>
              </AdminLayout>
            }
          /> */}
          <Route
            exact
            path={TABS.SETTINGS.TABS.PLAN.path}
            element={
              <AdminLayout>
                <Account currentTab={TABS.SETTINGS.label}>
                  <AccountTabSettings
                    updateValue={updateValue}
                    currentTab={TABS.SETTINGS.TABS.PLAN.label}
                  >
                    <AccountTabPlan />
                  </AccountTabSettings>
                </Account>
              </AdminLayout>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer closeOnClick pauseOnFocusLoss pauseOnHover theme="dark" />
    </>
  );
};

export default App;
