import React from 'react';
import '../../../styles/frontend.scss';

const SectionBanner = () => (
  <section className="custom-top-banner-gradient">
    <div className="container">
      <div className="row mb-5 justify-content-between row-banner">
        <div className="col-lg-7 mt-5 pt-5">
          <h6 className="banner-subheading-cls">Welcome to PAIGE</h6>
          <h1 className="banner-heading-cls">
            One tool to automate keyword research, content creation, and
            publishing
          </h1>
          <p style={{ display: 'none' }} className="mt-2 p-cls"></p>
          <a href="/sign-up">
            <button
              type="button"
              className="btn btn-light mt-5 button-banner-left"
            >
              Automate With Paige
            </button>
          </a>
        </div>
        <div className="col-lg-5 mt-5 flex-column justify-content-center custom-banner-img-bg">
          <div className="img-background text-center">
            <img
              className="d-none d-md-block robofly desktop-show img-fluid"
              src="/images/chatbot.png"
              alt="Welcome to Paige"
            />
            <img
              className="d-lg-none d-md-none robofly img-fluid"
              src="/images/mobile-hi.png"
              alt="Welcome to Paige and Hi"
            />
          </div>
          <div className="buttons-background d-flex justify-content-center align-items-end">
            <div className="button-container-cls mb-4">
              <button
                type="button"
                className="btn btn-light align-bottom custom-banner-btn-1"
              >
                Get Support
              </button>
              <button
                type="button"
                className="btn btn-light custom-banner-btn-2"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="hero-bg">&nbsp;</div>
        </div>
      </div>
    </div>
  </section>
);

export default SectionBanner;
