import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import { Redirect, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AccountTabCompetitionComponent from '../AccountTabCompetitionComponent.js';
import AccountTabFormLabelAndInput from '../AccountTabFormLabelAndInput.js';

const AccountTabSettingsBusiness = ({
  user = null,
  updateValue = null,
  children,
} = {}) => {
  const handleSubmit = async (values) => {};

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser);
  //   window.addEventListener('unload', handleLeavePage);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser);
  //     window.removeEventListener('unload', handleLeavePage);
  //     handleLeavePage();
  //   };
  // }, []);

  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = '';
  // };

  // const handleLeavePage = () => {
  //   alert('are you sure you want to leave?');
  // };

  const PostSettingsSchema = Yup.object().shape({
    // days: Yup.array(),
    business_name: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    industry: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    business_description: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    products_you_sell: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less'),
    services_you_offer: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less'),
    target_audience: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less'),
    // styles: ,
  });

  const handleBlur = ({ value = null, prevValue = null, name = '' } = {}) => {
    if (!_.isEqual(value, prevValue)) {
      updateValue({
        name: `posts_${name}`.replace('posts_posts_', 'posts_'),
        value,
      });
    }
  };

  const formFields = {
    business_name: {
      placeholder: 'Enter your business name (i.e. Acme Inc.)',
    },
    industry: {
      placeholder: 'Enter your industry (i.e. Jewelry E-Commerce)',
    },
    business_description: {
      placeholder: 'Enter a brief description (i.e. Jewelry E-Commerce)',
    },
    products_you_sell: {
      placeholder:
        "Leave blank if you don't sell products (max. 50 characters)",
    },
    services_you_offer: {
      placeholder:
        "Leave blank if you don't offer services (max. 50 characters)",
    },
    target_audience: {
      placeholder: '(max. 50 characters)',
    },
  };

  const getInitialValues = () => {
    let f = Object.keys(
      _.omitBy(
        formFields,
        (value, key) =>
          key !== 'render' &&
          Object.prototype.hasOwnProperty.call(value, 'render')
      ) || {}
    );
    let iV = Object.entries(
      _.pickBy(user, function (value, key) {
        return _.startsWith(key, 'posts_');
      })
    ).reduce((acc, [key, value]) => {
      acc[key.replace(/^posts_/, '')] = value;
      return acc;
    }, {});
    const defaults = _.fromPairs(f.map((key) => [key, '']));
    _.defaults(iV, defaults);
    return {
      ...iV,
      ...{
        posts_days: user?.posts_days || [],
        posts_style: user?.posts_style || [],
      },
    };
  };
  return (
    <>
      <Formik
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          try {
            (async (values, resetForm, setSubmitting) => {
              setSubmitting(true);
              let submitted = await handleSubmit(values);
              setSubmitting(false);
              resetForm();
            })(values, resetForm, setSubmitting);
          } catch (error) {
            console.error(error);
          }
        }}
        initialValues={getInitialValues()}
        validationSchema={PostSettingsSchema}
        enableReinitialize={true}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="account-profile">
              {Object.entries(formFields).map(([key, value], index) => {
                let name = value.name || key;
                let placeholder =
                  value.placeholder ||
                  value.label ||
                  _.startCase(_.toLower(name));
                return (
                  <AccountTabFormLabelAndInput
                    key={`form-label-and-input-${name}-${index}`}
                    name={name}
                    index={index}
                    value={value}
                    handleBlur={handleBlur}
                    prevValue={user[`posts_${_.snakeCase(name)}`]}
                    placeholder={placeholder}
                    touched={touched}
                    errors={errors}
                  />
                );
              })}
              <AccountTabCompetitionComponent
                values={values}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

AccountTabSettingsBusiness.propTypes = {};

export default connect(mapStateToProps)(AccountTabSettingsBusiness);
