import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  Button,
} from 'react-bootstrap';
import AccountTabSiteManualCreateAuthInstructionsList from './AccountTabSiteManualCreateAuthInstructionsList';

const AccountTabSiteManualCreateAuthModal = ({
  setShowAutoCreateAuthModal,
  setShowManualCreateAuthModal,
  showManualCreateAuthModal,
}) => (
  <Modal
    size="xl"
    show={showManualCreateAuthModal}
    onHide={() => {
      setShowManualCreateAuthModal(false);
    }}
  >
    <ModalHeader closeButton>
      <ModalTitle>Paige User Credentials Instructions</ModalTitle>
    </ModalHeader>
    <ModalBody
      style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 250px)' }}
    >
      <p>
        These instructions will show you how you can create a user for Paige and
        create a Wordpress Application Password. This allows Paige to post to
        your site on your behalf.
      </p>
      <AccountTabSiteManualCreateAuthInstructionsList />
    </ModalBody>
    <ModalFooter>
      {/* <Button
        variant="outline-primary"
        onClick={() => {
          setShowManualCreateAuthModal(false);
          setShowAutoCreateAuthModal(true);
        }}
      >
        Automate this for me
      </Button> */}
      <div style={{ flex: 1 }}></div>
      <Button
        onClick={() => {
          setShowManualCreateAuthModal(false);
        }}
      >
        OK
      </Button>
    </ModalFooter>
  </Modal>
);

export default AccountTabSiteManualCreateAuthModal;
