import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { firebase, firestore, functions } from '../../index';
import '../../styles/accountPlan.scss';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

const Timestamp = firebase.firestore.Timestamp;
const PLAN_ITEM_DEFAULTS = {
  interval: 'month',
  currency: 'usd',
};

const AccountTabPlan = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showConfirmSubscribeModal, setShowConfirmSubscribeModal] =
    useState(false);
  const [subscriptionUrl, setSubscriptionUrl] = useState(null);
  const [
    confirmSubscriptionWaitingForLink,
    setConfirmSubscriptionWaitingForLink,
  ] = useState(false);
  const subscriptionData = useSelector(
    (state) => state.SubscriptionState || {}
  );
  const products = useSelector((state) => state.ProductsState || []);
  const [subscriptionCancelledAlready, setSubscriptionCancelledAlready] =
    useState(null);
  const userData = useSelector((state) => state.UserState || {});
  const [
    showConfirmCancelSubscriptionModal,
    setShowConfirmCancelSubscriptionModal,
  ] = useState(false);

  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const date = new Date();
    const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
    setSubscriptionCancelledAlready(null);
    if (!isNaN(subscriptionData?.current?.cancel_at?.seconds)) {
      setSubscriptionCancelledAlready(
        subscriptionData.current.cancel_at.seconds < secondsSinceEpoch
      );
    }
  }, [subscriptionData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');

    if (status === 'success') {
      toast(
        'Subscription created. This may take a minute to update in the system.',
        { type: 'success' }
      );
      params.delete('status'); // Remove the status parameter
      navigate({ search: `?${params.toString()}` });
    } else if (status === 'cancel') {
      toast('Subscription was not created.', { type: 'error' });
      params.delete('status'); // Remove the status parameter
      navigate({ search: params.toString() });
    }
  }, [location.search, navigate]);

  // useEffect(() => {
  //   console.log({ products });
  // }, [products]);

  // const loadProducts = useCallback(() => {
  //   firestore
  //     .collection('products')
  //     .where('active', '==', true)
  //     .get()
  //     .then((snapshot) => {
  //       snapshot.forEach(async (productDoc) => {
  //         let product = {};
  //         const priceSnap = await productDoc.ref.collection('prices').get();
  //         priceSnap.forEach((priceDoc) => {
  //           product = {
  //             id: productDoc.id,
  //             ...productDoc.data(),
  //             prices: { id: priceDoc.id, ...priceDoc.data() },
  //           };
  //         });

  //         setProducts((products) => [...products, product]);
  //       });
  //     });
  // }, []);

  useEffect(() => {
    if (
      _.isString(subscriptionUrl) &&
      subscriptionUrl.length > 0 &&
      confirmSubscriptionWaitingForLink === true
    ) {
      window.location.href = subscriptionUrl;
    }
  }, [subscriptionUrl]);

  const loadCheckout = async (product) => {
    setShowConfirmSubscribeModal(true);
    const getCheckoutLinkCallable = functions.httpsCallable(
      'getCheckoutLinkCallable'
    );
    try {
      let response = await getCheckoutLinkCallable({
        customer_email: userData?.email,
        customer_name: userData?.name,
        customer_id: userData?.customer_id,
        user_id: userData?.uid,
        price: product?.price?.id,
      });
      if (
        _.isObject(response) &&
        _.isString(response.data) &&
        response.data.length > 0
      ) {
        setSubscriptionUrl(response.data);
        // window.location.replace(response.data);
      }
    } catch (error) {
      console.error(error);
    }
    // const docRef = await firestore
    //   .collection('customers')
    //   .doc(user.uid)
    //   .collection('checkout_sessions')
    //   .add({
    //     price: product.prices.id,
    //     success_url: window.location.href,
    //     cancel_url: window.location.href,
    //   });
    // docRef.onSnapshot(async (snap) => {
    //   const { error, sessionId } = snap.data();
    //   if (error) {
    //     toast(`An error occurred: ${error.message}`, { type: 'error' });
    //   }

    //   if (sessionId) {
    //     const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    //     stripe.redirectToCheckout({ sessionId });
    //   }
    // });
  };

  const formatPrice = (currency, amount) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format((amount / 100).toFixed(2));

  const cancelSubscriptions = async () => {
    let cancelSubscription = functions.httpsCallable('cancelSubscription');
    const canceled = await cancelSubscription({
      subscriptionId: subscriptionData?.current?.item?.subscription,
    });
  };

  const changeSubscriptions = async (priceId) => {
    let changeSubscription = functions.httpsCallable('changeSubscriptions');
    const changed = await changeSubscription({
      subscriptionId: subscriptionData?.current?.item?.subscription,
      priceId,
    });
  };

  const renderProductsTable = () => (
    <div className="products">
      {products.map((product, index) => (
        <div
          className={classNames('product', { highlight: index === 1 })}
          key={product.id}
        >
          <h3>{product.name}</h3>
          <p>{product.description}</p>
          <span>
            <b>US${product.price?.description}</b>
            <small>per month</small>
          </span>
          <button
            className="mt-1 btn btn-primary"
            onClick={() => loadCheckout(product)}
          >
            Subscribe
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {_.isObject(subscriptionData?.current) ? (
        <div>
          <div>
            <b>
              {subscriptionData?.current?.item?.price?.product?.name}
              <small> {subscriptionData?.current?.description}</small>
            </b>
            <p>
              <i>
                {formatPrice(
                  subscriptionData?.current?.item?.plan?.currency ||
                    PLAN_ITEM_DEFAULTS.currency,
                  subscriptionData?.current?.item?.plan?.amount
                )}{' '}
                per{' '}
                {subscriptionData?.current?.item?.plan?.interval ||
                  PLAN_ITEM_DEFAULTS.interval}
              </i>
            </p>
          </div>
          {subscriptionData.current.cancel_at_period_end !== true && (
            <p>
              Next payment will be at{' '}
              <b>
                {moment(
                  new Timestamp(
                    subscriptionData?.current?.current_period_end.seconds,
                    subscriptionData?.current?.current_period_end.nanoseconds
                  ).toDate()
                ).format('DD MMMM Y')}
              </b>
            </p>
          )}
          {subscriptionData?.current?.cancel_at && (
            <div>
              <p>
                {`You're subscription ${
                  subscriptionCancelledAlready
                    ? 'has been canceled as of'
                    : 'will be canceled on'
                }
                ${moment(
                  new Timestamp(
                    subscriptionData?.current?.current_period_end.seconds,
                    subscriptionData?.current?.current_period_end.nanoseconds
                  ).toDate()
                ).format('MMMM D, Y')}`}
              </p>
            </div>
          )}
          {!subscriptionData?.current?.cancel_at && (
            <>
              <Button
                onClick={() => {
                  setShowConfirmCancelSubscriptionModal(true);
                }}
                variant="outline-danger"
              >
                Cancel Subscription
              </Button>{' '}
              <Button onClick={() => setShowTable((current) => !current)}>
                {showTable ? 'Hide Subscription Table' : 'Change Subscription'}
              </Button>
            </>
          )}
          {showTable ||
            (subscriptionData?.current?.cancel_at_period_end === true && (
              <>
                {!showTable && (
                  <h3
                    style={{
                      marginTop: 50,
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    Subscribe again to re-activate your account
                  </h3>
                )}
                {renderProductsTable()}
              </>
            ))}
          <ConfirmModal
            show={showConfirmCancelSubscriptionModal}
            onClose={() => {
              setShowConfirmCancelSubscriptionModal(false);
            }}
            title="Confirm Subscription Cancellation"
            message="Are you sure you'd like to cancel your subscription?"
            onClickCancel={() => {
              setShowConfirmCancelSubscriptionModal(false);
            }}
            onClickConfirm={() => {
              cancelSubscriptions();
              setShowConfirmCancelSubscriptionModal(false);
            }}
            okButtonText="Cancel Subscription"
            cancelButtonText="Go Back"
            okButtonColor="danger"
            cancelButtonColor="primary"
            loading={confirmSubscriptionWaitingForLink}
          />
        </div>
      ) : (
        <>{renderProductsTable()}</>
      )}
      <ConfirmModal
        show={showConfirmSubscribeModal}
        onClose={() => {
          setShowConfirmCancelSubscriptionModal(false);
          setConfirmSubscriptionWaitingForLink(false);
        }}
        title="Redirect to payment"
        message="You will be redirected to our checkout process. When you complete your subscription payment, you will be redirected back to this subscription page."
        onClickCancel={() => {
          setShowConfirmSubscribeModal(false);
          setConfirmSubscriptionWaitingForLink(false);
        }}
        onClickConfirm={() => {
          if (_.isString(subscriptionUrl) && subscriptionUrl.length > 0) {
            window.location.href = subscriptionUrl;
          } else {
            setConfirmSubscriptionWaitingForLink(true);
          }
        }}
        loading={confirmSubscriptionWaitingForLink}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

AccountTabPlan.propTypes = {};

export default connect(mapStateToProps)(AccountTabPlan);
