import { SET_USER } from './userConstants';

/**
 * Set current user
 */
export function setUser(payload) {
  return {
    type: SET_USER,
    payload,
  };
}
