export const TABS = {
  // DASHBOARD: {
  //   label: 'Dashboard',
  //   path: '/account',
  // },
  POSTS: {
    label: 'Posts',
    path: '/account/posts',
    TABS: {
      REVIEW: {
        label: 'Review',
        path: '/account/posts/review',
      },
      STRATEGY: {
        label: 'Strategy',
        path: '/account/posts/strategy',
      },
    },
  },
  SETTINGS: {
    label: 'Settings',
    path: '/account/settings',
    TABS: {
      POSTS: {
        label: 'Posts',
        path: '/account/settings/posts',
      },
      BUSINESS: {
        label: 'Business',
        path: '/account/settings/business',
      },
      SITE: {
        label: 'Site',
        path: '/account/settings/site',
      },
      // PROFILE: {
      //   label: 'Profile',
      //   path: '/account/settings/profile',
      // },
      PLAN: {
        label: 'Subscription',
        path: '/account/settings/subscription',
      },
    },
  },
};
