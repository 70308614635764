import React, { Component } from 'react';

const Footer = () => (
  <>
    <section className="main-footer">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-12">
            <img src="/images/footer-image.png" />
          </div>
          <div className="col-md-12 d-flex justify-content-center flex-nowrap">
            <ul className="list-group list-group-horizontal">
              <li className="list-group-item">About</li>
              <li className="list-group-item">Resources</li>
              <li className="list-group-item">Blog</li>
              <li className="list-group-item list-design">Contact</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="foot-style">
        <div className="text">
          <p>©2023 PaigeSeo Privacy Policy | Terms of Use</p>
        </div>
      </div>
    </section>

    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>

    <script src="https://code.jquery.com/jquery-3.7.0.min.js"></script>
    <script src="/js/owl.carousel.min.js"></script>

    <script src="/js/main.js"></script>
  </>
);

export default Footer;
