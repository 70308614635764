import produce from 'immer';

import { SET_POST } from './postConstants';

const INITIAL_STATE = {};

export const postReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_POST:
        return action.payload;

      default:
        return state;
    }
  });
