import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import '../styles/main.scss';

const ApprovePostForPublishFailed = () => (
  <div id="page-approve-publish-post-failed">
    <Container className="approve-publish-post-failed-container">
      <p>
        There was an error approving your post. Please visit{' '}
        <Link to="/account/posts/review">your account post review page</Link> or
        contact <a href="mailto:info@paigeseo.com">info@paigeseo.com</a>
      </p>
    </Container>
  </div>
);

export default ApprovePostForPublishFailed;
