import React from 'react';
const SectionSEOStrategy = () => (
  <section className="custom-seo-img-bg">
    <div className="container">
      <div className="row mb-5 justify-content-between row-seo">
        <div className="col-lg-4 col-md-6  mt-5">
          <div className="text-center">
            <img
              src="/images/seo-strategy-bot.png"
              className="img-fluid robofly w-70"
              alt=""
            />
            <div className="bg-green">&nbsp;</div>
          </div>
        </div>
        <div className="col-lg-7 col-md-6 mt-5 pt-5">
          <h6 className="banner-subheading-cls">Featured</h6>
          <h2 className="h2-custom-cls">You deserve an easier SEO strategy </h2>
          <p className="mt-2 p-cls">
            Paige is an all-in-one SEO content automation tool. To start, she
            actively monitors your website to determine what type of content
            your site needs to rank higher on search engines and generate more
            leads.
          </p>
          <p className="mt-2 p-cls">
            After determining what content your site needs, Paige produces the
            content in an optimized format for search engines. Finally, she
            publishes this content directly to your website for search engines
            to start crawling and for customers to start finding.
          </p>
        </div>
      </div>
    </div>
  </section>
);
export default SectionSEOStrategy;
