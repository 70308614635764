import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
  InputGroup,
  FloatingLabel,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';

import '../styles/account.scss';
import { TABS } from '../AppTabs';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';
import { hasNotificationForUserAndPath } from '../utils/notificationHelpers';

const Account = ({ user, children, currentTab }) => {
  return (
    <div id="page-account">
      <Container className="account-container">
        <Row className="account-container-row">
          <Col sm={2}>
            <ListGroup>
              {Object.values(TABS)
                .filter((t) => {
                  return t.path.split('/').length < 4;
                })
                .map((displayTab, index) => (
                  <ListGroupItem
                    key={`list-tab-${index}`}
                    className={currentTab === displayTab.label ? 'active' : ''}
                  >
                    <Link to={displayTab.path}>{displayTab.label}</Link>
                    {hasNotificationForUserAndPath({
                      user,
                      path: `${_.snakeCase(displayTab.label)}*`,
                    }) && <FaCircle className="notification" color="#dd3448" />}
                  </ListGroupItem>
                ))}
            </ListGroup>
          </Col>
          <Col sm={10} className="account-content-col">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>{currentTab}</h1>
            </div>
            <div className="account-content-card">{children}</div>
            {/* <div className="account-content-card">{renderCurrentTab()}</div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

Account.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(Account);
