import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Form as BootstrapForm, FormLabel } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { firestore } from '../../..';
import AccountTabFormLabelAndInput from '../AccountTabFormLabelAndInput';
import { toast } from 'react-toastify';

const maxWritingStyles = 4;

const writingStyles = [
  'Professional',
  'Technical',
  'Funny',
  'Sarcastic',
  'Personal',
  'Persuasive',
  'Sales',
  'Simple',
].sort();

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const AccountTabSettingsPosts = ({ updateValue = null, children } = {}) => {
  const user = useSelector((state) => state.UserState || {});
  const schedule = useSelector((state) => state.ScheduleState || {});

  const handleSubmit = async (values) => {};
  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser);
  //   window.addEventListener('unload', handleLeavePage);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser);
  //     window.removeEventListener('unload', handleLeavePage);
  //     handleLeavePage();
  //   };
  // }, []);

  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = '';
  // };

  // const handleLeavePage = () => {
  //   alert('are you sure you want to leave?');
  // };

  const PostSettingsSchema = Yup.object().shape({
    // days: Yup.array(),
    business_name: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    industry: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    business_description: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    products_you_sell: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less'),
    services_you_offer: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less'),
    target_audience: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less'),
    // styles: ,
  });
  const renderStyleSection = ({
    name = '',
    type = 'text',
    autoComplete = 'off',
    label = 'Writing Style',
    index,
    placeholder,
    touched,
    errors,
    values,
    setFieldValue,
  } = {}) => (
    <React.Fragment key={`section--${index}--${name.replace(' ', '-')}`}>
      <FormLabel
        key={`label--${index}--${name.replace(' ', '-')}`}
        className="account-form-label"
      >
        {label}
      </FormLabel>
      <BootstrapForm.Group
        key={`group--${index}--${name.replace(' ', '-')}`}
        className="account-form-wrapper"
        style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}
      >
        {writingStyles.map((style, index) => {
          return (
            <BootstrapForm.Check
              key={`check--${index}--${style.replace(' ', '-')}`}
              label={style}
              type="checkbox"
              checked={(values.posts_style || []).includes(style) === true}
              onChange={(e) => {
                _handleCheckChange({
                  checked: e.target.checked,
                  newValue: style,
                  prevValue: values.posts_style,
                  setFieldValue,
                  maxValues: maxWritingStyles,
                  maxMessage:
                    'Too many styles chosen, please limit your selections to four.',
                  valueField: 'posts_style',
                });
              }}
            />
          );
        })}
      </BootstrapForm.Group>
    </React.Fragment>
  );

  const renderPostAsDraft = ({
    name = '',
    type = 'text',
    autoComplete = 'off',
    label = 'Auto-post',
    index,
    placeholder,
    touched,
    errors,
    values,
    setFieldValue,
  } = {}) => (
    <React.Fragment key={`section--${index}--${name.replace(' ', '-')}`}>
      <FormLabel
        key={`label--${index}--${name.replace(' ', '-')}`}
        className="account-form-label"
      >
        {label}
      </FormLabel>
      <BootstrapForm.Group
        key={`group--${index}--${name.replace(' ', '-')}`}
        className="account-form-wrapper"
        style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}
      >
        <BootstrapForm.Check
          key={`check--${index}--post-as-draft`}
          label="Post to website automatically (without having to approve)"
          type="switch"
          checked={values.posts_as_draft === false}
          onChange={(e) => {
            if (_.isFunction(updateValue)) {
              updateValue({
                name: `posts_as_draft`,
                value: !e.target.checked,
              });
            }
            if (_.isFunction(setFieldValue)) {
              setFieldValue('posts_as_draft', !e.target.checked);
            }
          }}
        />
      </BootstrapForm.Group>
    </React.Fragment>
  );

  const handleBlur = ({ value = null, prevValue = null, name = '' } = {}) => {
    if (!_.isEqual(value, prevValue)) {
      updateValue({
        name: `posts_${name}`.replace('posts_posts_', 'posts_'),
        value,
      });
    }
  };

  const _handleCheckChange = ({
    checked = null,
    newValue = null,
    prevValue = [],
    setFieldValue = null,
    maxValues = -1,
    maxMessage = 'Too many selections',
    valueField = '',
  }) => {
    if (checked === true) {
      if (
        !!maxValues &&
        _.isInteger(maxValues) &&
        (parseInt(maxValues < 0) ||
          parseInt(maxValues) > (prevValue || []).length)
      ) {
        if (_.isFunction(updateValue)) {
          updateValue({
            name: `posts_${valueField}`.replace('posts_posts_', 'posts_'),
            value: _.uniq([...prevValue, ...[newValue]]).sort(),
          });
        }
        if (_.isFunction(setFieldValue)) {
          setFieldValue(
            valueField,
            _.uniq([...prevValue, ...[newValue]]).sort()
          );
        }
      } else {
        toast(maxMessage, { type: 'error' });
      }
    } else if (checked === false) {
      if (_.isFunction(updateValue)) {
        updateValue({
          name: `posts_${valueField}`.replace('posts_posts_', 'posts_'),
          value: _.pull([...prevValue], newValue).sort(),
        });
      }
      if (_.isFunction(setFieldValue)) {
        setFieldValue(valueField, _.pull([...prevValue], newValue).sort());
      }
    }
  };

  const renderDaysSection = ({
    name = '',
    type = 'text',
    autoComplete = 'off',
    label = 'Days to Post',
    index,
    placeholder,
    touched,
    errors,
    values,
    setFieldValue,
  } = {}) => (
    <React.Fragment key={`section--${index}--${name.replace(' ', '-')}`}>
      <FormLabel
        key={`label--${index}--${name.replace(' ', '-')}`}
        className="account-form-label"
      >
        {label}
      </FormLabel>
      <BootstrapForm.Group
        key={`group--${index}--${name.replace(' ', '-')}`}
        className="account-form-wrapper calendar"
        style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}
      >
        {daysOfWeek.map((day, index) => {
          return (
            <BootstrapForm.Check
              key={`check--${index}--${day.replace(' ', '-')}`}
              label={day}
              type="checkbox"
              checked={(values.posts_days || []).includes(index) === true}
              onChange={(e) => {
                _handleCheckChange({
                  checked: e.target.checked,
                  newValue: index,
                  prevValue: values.posts_days,
                  setFieldValue,
                  maxValues: user.posts_per_wk,
                  maxMessage:
                    !isNaN(user.posts_per_wk) && parseInt(user.posts_per_wk) > 0
                      ? 'Too many days selected, please un-check other days first or upgrade your subscription.'
                      : 'You do not have an active subscription. Activate your subscription and then you can choose your days to post',
                  valueField: 'posts_days',
                });
              }}
            />
          );
        })}
      </BootstrapForm.Group>
      {_.isObject(schedule) && !_.isNil(schedule.post_next_date) && (
        <>
          <FormLabel
            key={`label--post_next_date`}
            className="account-form-label"
          ></FormLabel>
          <div
            className="account-form-wrapper next-scheduled-post"
            style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}
          >
            <>
              Next Scheduled Post:{' '}
              {!_.isNil(schedule.post_next_date) &&
                _.isFunction(schedule.post_next_date.toDate) && (
                  <>
                    {schedule.post_next_date
                      .toDate()
                      .toLocaleString()
                      .replace(/:00(?!.*:00)/, '')}
                  </>
                )}
            </>
          </div>
        </>
      )}
    </React.Fragment>
  );
  const formFields = {
    draft: {
      render: renderPostAsDraft,
    },
    styles: {
      render: renderStyleSection,
    },
    days: {
      render: renderDaysSection,
    },
  };

  const getInitialValues = () => {
    let f = Object.keys(
      _.omitBy(
        formFields,
        (value, key) =>
          key !== 'render' &&
          Object.prototype.hasOwnProperty.call(value, 'render')
      ) || {}
    );
    let iV = Object.entries(
      _.pickBy(user, function (value, key) {
        return _.startsWith(key, 'posts_');
      })
    ).reduce((acc, [key, value]) => {
      acc[key.replace(/^posts_/, '')] = value;
      return acc;
    }, {});
    const defaults = _.fromPairs(f.map((key) => [key, '']));
    _.defaults(iV, defaults);
    return {
      ...iV,
      ...{
        posts_days: user?.posts_days || [],
        posts_style: user?.posts_style || [],
        posts_as_draft: user?.posts_as_draft === true,
      },
    };
  };
  return (
    <>
      <Formik
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          try {
            (async (values, resetForm, setSubmitting) => {
              setSubmitting(true);
              let submitted = await handleSubmit(values);
              setSubmitting(false);
              resetForm();
            })(values, resetForm, setSubmitting);
          } catch (error) {
            console.error(error);
          }
        }}
        initialValues={getInitialValues()}
        validationSchema={PostSettingsSchema}
        enableReinitialize={true}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="account-profile">
              {Object.entries(formFields).map(([key, value], index) => {
                let name = value.name || key;
                let placeholder =
                  value.placeholder ||
                  value.label ||
                  _.startCase(_.toLower(name));
                if (_.isFunction(value.render)) {
                  return value.render({
                    name,
                    type: value.type,
                    autoComplete: value.autoComplete,
                    label: value.label,
                    placeholder,
                    index,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                  });
                }
                return (
                  <AccountTabFormLabelAndInput
                    name={name}
                    index={index}
                    value={value}
                    handleBlur={handleBlur}
                    prevValue={user[`posts_${_.snakeCase(name)}`]}
                    placeholder={placeholder}
                    touched={touched}
                    errors={errors}
                  />
                );
              })}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AccountTabSettingsPosts;
