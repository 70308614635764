import produce from 'immer';

import { SET_SCHEDULE } from './scheduleConstants';

const INITIAL_STATE = {};

export const scheduleReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SCHEDULE:
        return {
          ...state,
          ...action.payload,
        };

      default:
        return state;
    }
  });
