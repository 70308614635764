import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import {
  Field,
  FieldArray,
  Formik,
  useFormik,
  Form,
  useFormikContext,
} from 'formik';
import { Redirect, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
} from 'react-bootstrap';
import { functions } from '../../..';
import { toast } from 'react-toastify';
import { FaCheckDouble, FaTasks } from 'react-icons/fa';
import AccountTabSiteAuthInstructions from './AccountTabSiteAuthInstructions';
import AccountTabSiteAutomaticCreateAuthModal from './AccountTabSiteAutomaticCreateAuthModal';
import AccountTabSiteManualCreateAuthModal from './AccountTabSiteManualCreateAuthModal';

const AccountTabSiteDetails = ({ user, updateValue = null } = {}) => {
  const handleSubmit = async (values) => {};
  // const { values, submitForm } = useFormikContext();
  const [showAutoCreateAuthModal, setShowAutoCreateAuthModal] = useState(false);
  const [showManualCreateAuthModal, setShowManualCreateAuthModal] =
    useState(false);
  const [showAuthenticationInstructions, setShowAuthenticationInstructions] =
    useState(false);
  const [autoCreateUsernameValue, setAutoCreateUsernameValue] = useState('');
  const [autoCreatePasswordValue, setAutoCreatePasswordValue] = useState('');
  const [autoCreatingCredentialsLoading, setAutoCreatingCredentialsLoading] =
    useState(false);
  const [testingWPConnection, setTestingWPConnection] = useState(false);
  useEffect(() => {
    setShowAuthenticationInstructions(!_hasAuthenticationSettings());
  }, []);

  const writingStyles = [
    'Professional',
    'Technical',
    'Funny',
    'Sarcastic',
    'Personal',
    'Persuasive',
    'Sales',
    'Simple',
  ].sort();

  const _handleSubmitAdminCredentials = () => {
    setAutoCreatingCredentialsLoading(true);
    setTimeout(() => {
      setAutoCreatingCredentialsLoading(false);
    }, 1000);
  };

  const testWPConnection = (values) => {
    setTestingWPConnection(true);
    const testWPConnectionCallable = functions.httpsCallable(
      'testWPConnectionCallable'
    );
    try {
      (async () => {
        const result = await testWPConnectionCallable({
          userId: user.uid,
        });
        if (result.data.success === true) {
          toast('Connection is set up properly, you are ready to go!', {
            type: 'success',
          });
          setShowAuthenticationInstructions(false);
        } else {
          toast(
            `Connection failed.${
              _.isString(result.data.message) && result.data.message.length > 0
                ? ` ${result.data.message}`
                : ''
            }`,
            {
              type: 'error',
            }
          );
        }
        setTestingWPConnection(false);
      })();
    } catch (error) {
      console.error(error);
      toast(`Connection failed`, {
        type: 'error',
      });
      setTestingWPConnection(false);
    }
  };
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    applicationPassword: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{4}$/,
        'Incorrect application password format'
      )
      .required('Required'),
    password: Yup.string().min(6, 'Must be 6 characters or more'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });
  const formFields = {
    site_url: { autoComplete: 'url', label: 'Site URL' },
    // site_username: {
    //   autoComplete: 'username',
    //   label: 'Wordpress Username',
    //   placeholder: 'ie. "paige"',
    // },
    // site_app_pw: {
    //   label: 'Wordpress Application Password',
    //   placeholder: 'ie. ABCD 1234 EFGH 5678 IJKL 9000',
    //   autoComplete: 'off',
    // },
  };

  const handleBlur = ({ value = null, prevValue = null, name = '' } = {}) => {
    if (!_.isEqual(value, prevValue)) {
      updateValue({ name, value });
    }
  };

  const renderFormField = ({
    name,
    index,
    value,
    placeholder = '',
    touched,
    errors,
  }) => (
    <React.Fragment key={`form-section-${name}-${index}`}>
      <FormLabel className="account-form-label">
        {value.label || _.startCase(_.toLower(name))}
      </FormLabel>
      <BootstrapForm.Group className="account-form-wrapper">
        <Field
          label={value.label || _.startCase(_.toLower(name))}
          name={name}
          type={value.type || 'text'}
          placeholder={placeholder}
          autoComplete={value.autoComplete || name}
          isInvalid={touched[name] && !!errors[name]}
          onBlur={(e) => {
            handleBlur({
              value: e.target.value,
              prevValue: user[name],
              name,
            });
          }}
          as={BootstrapForm.Control}
        />
        {touched.name && errors.name && (
          <BootstrapForm.Control.Feedback type="invalid">
            {errors.name}
          </BootstrapForm.Control.Feedback>
        )}
      </BootstrapForm.Group>
    </React.Fragment>
  );

  const _hasAuthenticationSettings = () => {
    return (
      _.isString(user.site_username) &&
      user.site_username.length > 0 &&
      _.isString(user.site_app_pw) &&
      user.site_app_pw.length > 0
    );
  };

  return (
    <>
      <Formik
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          try {
            (async (values, resetForm, setSubmitting) => {
              setSubmitting(true);
              let submitted = await handleSubmit(values);
              setSubmitting(false);
              resetForm();
            })(values, resetForm, setSubmitting);
          } catch (error) {
            console.error(error);
          }
        }}
        initialValues={{
          site_url: user?.site_url || '',
          site_app_pw: user?.site_app_pw || '',
          site_username: user?.site_username || '',
          competitor_domains: user?.competitor_domains || [],
        }}
        validationSchema={SignupSchema}
        enableReinitialize={true}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="account-profile">
              {Object.entries(formFields).map(([key, value], index) => {
                let name = value.name || key;
                let placeholder =
                  value.placeholder ||
                  value.label ||
                  _.startCase(_.toLower(name));
                if (_.isFunction(value.render)) {
                  return value.render({
                    name,
                    type: value.type,
                    autoComplete: value.autoComplete,
                    label: value.label,
                    placeholder,
                    index,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                  });
                }
                return renderFormField({
                  name,
                  index,
                  value,
                  placeholder,
                  touched,
                  errors,
                });
              })}
              <h2 className="account-subsection-header account-subsection-full-width">
                Authentication Settings
              </h2>
              {renderFormField({
                name: 'site_username',
                value: {
                  label: 'Wordpress Username',
                  type: 'text',
                  autoComplete: 'off',
                },
                index: Object.keys(formFields).length + 1,
                placeholder: 'Username (ie. paige)',
                touched,
                errors,
              })}
              {renderFormField({
                name: 'site_app_pw',
                value: {
                  label: 'Wordpress Application Password',
                  type: 'password',
                  autoComplete: 'off',
                },
                index: Object.keys(formFields).length + 1,
                placeholder: 'ie. ABCD 1234 EFGH IJKL 5678 MNOP',
                touched,
                errors,
              })}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  gridColumn: '1 / span 2',
                  marginBottom: 30,
                }}
              >
                {_hasAuthenticationSettings() && (
                  <>
                    {testingWPConnection === true ? (
                      <p>Testing connection...</p>
                    ) : (
                      <Button
                        className="mt-1"
                        onClick={() => {
                          testWPConnection(values);
                        }}
                      >
                        Test Connection
                      </Button>
                    )}
                  </>
                )}

                <div style={{ flex: 1 }}></div>
                <Button
                  variant="outline-primary"
                  className="mt-1"
                  onClick={() => {
                    setShowAuthenticationInstructions(
                      !showAuthenticationInstructions
                    );
                  }}
                >
                  {showAuthenticationInstructions
                    ? 'Hide instructions'
                    : 'Help me with this'}
                </Button>
              </div>

              {showAuthenticationInstructions && (
                <AccountTabSiteAuthInstructions
                  setShowAutoCreateAuthModal={setShowAutoCreateAuthModal}
                  setShowManualCreateAuthModal={setShowManualCreateAuthModal}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
      {/* <AccountTabSiteAutomaticCreateAuthModal
        showAutoCreateAuthModal={showAutoCreateAuthModal}
        setShowAutoCreateAuthModal={setShowAutoCreateAuthModal}
        autoCreatingCredentialsLoading={autoCreatingCredentialsLoading}
        autoCreateUsernameValue={autoCreateUsernameValue}
        setAutoCreateUsernameValue={setAutoCreateUsernameValue}
        autoCreatePasswordValue={autoCreatePasswordValue}
        setAutoCreatePasswordValue={setAutoCreatePasswordValue}
        _handleSubmitAdminCredentials={_handleSubmitAdminCredentials}
      /> */}
      <AccountTabSiteManualCreateAuthModal
        setShowAutoCreateAuthModal={setShowAutoCreateAuthModal}
        setShowManualCreateAuthModal={setShowManualCreateAuthModal}
        showManualCreateAuthModal={showManualCreateAuthModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

AccountTabSiteDetails.propTypes = {};

export default connect(mapStateToProps)(AccountTabSiteDetails);
