import { SET_CURRENT_PRODUCTS, CLEAR_PRODUCTS } from './productsConstants';

/**
 * Set current user
 */
export function setCurrentProducts(payload) {
  return {
    type: SET_CURRENT_PRODUCTS,
    payload,
  };
}
export function clearProducts() {
  return {
    type: CLEAR_PRODUCTS,
  };
}
