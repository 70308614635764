import produce from 'immer';

import {
  CLEAR_SUBSCRIPTION,
  SET_CURRENT_SUBSCRIPTION,
} from './subscriptionsConstants';

const INITIAL_STATE = {
  data: [],
  memberSince: null,
  invoices: [],
  status: null,
};

export const subscriptionReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_SUBSCRIPTION:
        return {
          ...state,
          ...action.payload,
        };

      case CLEAR_SUBSCRIPTION:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
