import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../Footer';
import Nav from '../Nav';
class PublicLayout extends React.Component {
  render(props) {
    return (
      <>
        <Nav menu="public" />
        {this.props.children}
        <Footer />
      </>
    );
  }
}

PublicLayout.propTypes = {
  children: PropTypes.node,
};

export default PublicLayout;
