import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Footer from '../Footer';
import Nav from '../Nav';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../..';

const AdminLayout = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (!user && !loading) {
      navigate(
        '/login?redirect=' +
          encodeURIComponent(location.pathname + location.search)
      );
    }
  }, [user, loading]);

  return (
    <>
      <Nav menu="account" />
      {loading === true ? (
        <div className="page-loading-auth">Loading account...</div>
      ) : (
        <>{children}</>
      )}
      <Footer />
    </>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};

export default AdminLayout;
