import React from 'react';

const AccountTabSiteManualCreateAuthInstructionsList = () => (
  <ol className="manual-user-credentials-instructions-list">
    <li>
      <h5>Create a new Wordpress User</h5>
      <ol type="a">
        <li>Log in to your wordpress website</li>
        <li>Navigate to the Users tab</li>
        <li>Click Add New at the top of the page</li>
        <li>Enter the username 'paige'</li>
        <li>
          Enter whatever email you would like. It should be an email address you
          could access.
        </li>
        <li>Enter a secure password.</li>
        <li>Make sure to change the Role to Editor</li>
        <li>Click Add New User</li>
      </ol>
    </li>
    <li>
      <h5>Create the Application Password</h5>
      <ol type="a">
        <li>Navigate to the Users tab</li>
        <li>
          Click the username of the user you just created (should be 'paige')
        </li>
        <li>
          Scroll almost all the way to the bottom and find a section called
          Application Passwords
        </li>
        <li>
          In the field called New Application Password Name enter in 'paige'
        </li>
        <li>
          The page wills how you a section that says Your new password for paige
          is:
        </li>
        <li>
          <strong>
            Copy this password. This is what you will need to enter into your
            Paige account.
          </strong>{' '}
          Once you leave this page, you cannot get this password again. If this
          happens, you can just create a new one.
        </li>
        <li>
          Note: At any time you can revoke this application password and Paige
          will no longer have access.
        </li>
      </ol>
    </li>
    <li>
      <h5>Enter Newly-Created Username and Password into Paige</h5>
      <ol type="a">
        <li>Return here to Paige</li>
        <li>Click "I have my credentials"</li>
        <li>
          Enter your username (should be 'paige' or whatever you used in step
          1d)
        </li>
        <li>Enter your application password you copied from step 2f</li>
        <li>
          The page wills how you a section that says "Your new password for
          paige is:"
        </li>
        <li>
          Copy this password. This is what you will need to enter into your
          Paige account
        </li>
      </ol>
    </li>
  </ol>
);

export default AccountTabSiteManualCreateAuthInstructionsList;
