import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import {
  Accordion,
  Table,
  Form as BootstrapForm,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  InputGroup,
  FloatingLabel,
  FormLabel,
} from 'react-bootstrap';

import {
  FaCheck,
  FaPenNib,
  FaPlus,
  FaRegCalendarAlt,
  FaSortDown,
  FaSortUp,
} from 'react-icons/fa';
import { firebase } from '../../../index.js';

import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { clearNotifications } from '../../../utils/firebaseHelpers.js';

const keywordRange = { min: 1, max: 10 };

const abbreviators = {
  NONE: {
    label: '',
    magnitude: 1,
    decimals: 0,
  },
  // THOUSANDS: {
  //   label: 'k',
  //   magnitude: 1000,
  //   decimals: 1,
  // },
  // MILLIONS: {
  //   label: 'M',
  //   magnitude: 1000000,
  //   decimals: 2,
  // },
  // BILLIONS: {
  //   label: 'B',
  //   magnitude: 1000000000,
  //   decimals: 3,
  // },
};

const dataFields = {
  USE: {
    id: 'use',
    label: 'In use',
    align: 'left',
    render: ({ strategy, index, updateDBValue, keyword }) => (
      <BootstrapForm.Switch
        checked={keyword.use === true}
        onChange={(e) => {
          let numSelected = strategy.keywords.filter(
            (k) => k.use === true
          ).length;
          if (e.target.checked === true && numSelected >= keywordRange.max) {
            toast(
              `You cannot select more than ${keywordRange.max} keywords in a given month.`,
              { type: 'warning' }
            );
          } else if (
            e.target.checked === false &&
            numSelected <= keywordRange.min
          ) {
            toast(
              `You need to have at least ${keywordRange.min} keyword${
                keywordRange.min === 1 ? '' : 's'
              } in a given month.`,
              { type: 'warning' }
            );
          } else {
            updateDBValue({
              month: strategy.id,
              index,
              use: e.target.checked,
            });
          }
        }}
      />
    ),
  },
  KEYWORD: {
    id: 'keyword',
    label: 'Keyword',
    align: 'left',
    render: ({ keyword }) => _.startCase(_.toLower(keyword.keyword)),
  },
  COMPETITION: { label: 'Competition', id: 'competition' },
  DIFFICULTY: { label: 'Difficulty', id: 'keyword_difficulty' },
  VOLUME: {
    label: ({ volumeAbbreviator }) => {
      return `Search Vol.${
        volumeAbbreviator.label !== ''
          ? ` (in ~${volumeAbbreviator.label})`
          : ''
      }`;
    },
    id: 'search_volume',
    defaultSortDirection: 0,
    align: 'right',
    render: ({ keyword, volumeAbbreviator }) => (
      <>{`${
        keyword.search_volume / volumeAbbreviator.magnitude < 100
          ? (keyword.search_volume / volumeAbbreviator.magnitude).toFixed(
              volumeAbbreviator.decimals
            )
          : Math.round(
              keyword.search_volume / volumeAbbreviator.magnitude
            ).toLocaleString()
      }`}</>
    ),
  },
  SCORE: {
    label: 'Paige Score',
    id: 'score',
    defaultSortDirection: 0,
    render: ({ keyword }) => (
      <strong style={{ color: '#018efe', fontSize: '1.2em' }}>
        {keyword.score}
      </strong>
    ),
  },
};

const AccountTabPostsStrategy = ({ user, strategiesFromDB } = {}) => {
  const [strategies, setStrategies] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [sortField, setSortField] = useState(dataFields.SCORE);
  const [sortDirection, setSortDirection] = useState(0); // 0 = DESC, 1 = ASC
  const [activeMonthIndex, setActiveMonthIndex] = useState(null);
  const [volumeAbbreviator, setVolumeAbbreviator] = useState(abbreviators.NONE);
  const [showAddCustomKeywordModal, setShowAddCustomKeywordModal] =
    useState(false);
  const [customKeywordInputValue, setCustomKeywordInputValue] = useState('');
  const [keywordUploading, setKeywordUploading] = useState(false);

  useEffect(() => {
    clearNotifications({
      notificationFields: ['notifications.posts_strategy'],
      userId: user.uid,
    });
    return () => {
      clearNotifications({
        notificationFields: ['notifications.posts_strategy'],
        userId: user.uid,
      });
    };
  }, []);

  useEffect(() => {
    let s = [...strategiesFromDB];
    if (_.isArray(s) && s.length > 0) {
      if (!(_.isArray(strategies) && strategies.length > 0)) {
        const today = new Date();
        let sortUp = s.sort((a, b) => (a.active_at < b.active_at ? -1 : 1));

        const maxIndex = sortUp.reduce((maxIndex, obj, index) => {
          const objDate = obj.active_at.toDate(); // Convert Firestore Timestamp to JavaScript Date object
          if (
            objDate < today &&
            objDate > sortUp[maxIndex].active_at.toDate()
          ) {
            return index;
          }
          return maxIndex;
        }, 0);
        setCurrentIndex(maxIndex);
        setActiveMonthIndex(maxIndex);
      }
      if (
        _.isInteger(activeMonthIndex) &&
        parseInt(activeMonthIndex) < s.length
      ) {
        s = sortKeywordsForVisibleMonthIndex({
          strategiesData: s,
          visibleMonthIndex: activeMonthIndex,
        });
      }
    }
    _updateAbbreviators(
      (s[activeMonthIndex]?.keywords || []).map((k) => k.search_volume).sort()
    );
    setStrategies(s);
  }, [strategiesFromDB, sortField, sortDirection, activeMonthIndex]);

  const _handleAddCustomKeyword = () => {
    try {
      setKeywordUploading(true);
      let monthId = strategies[activeMonthIndex].id;
      const docRef = firebase
        .firestore()
        .collection(`strategy/${user.uid}/months`)
        .doc(monthId);

      let update = strategies.find((m) => m.id === monthId);
      let keywords = [...update.keywords].sort((a, b) => a.index - b.index);
      keywords.push({ keyword: customKeywordInputValue });

      /* In order to update this back correctly we need to
        sort by the previous index and then remove the index field */
      update.keywords = keywords;
      setKeywordUploading(true);
      docRef
        .update(update, { merge: true })
        .then(() => {
          toast(
            `Keyword ${_.startCase(_.toLower(customKeywordInputValue))} added`,
            { type: 'success' }
          );
          setCustomKeywordInputValue('');
          setShowAddCustomKeywordModal(false);
        })
        .catch((error) => {
          toast(
            `Keyword "${_.startCase(
              _.toLower(customKeywordInputValue)
            )}" added`,
            { type: 'success' }
          );
        })
        .finally(() => {
          setKeywordUploading(false);
        });
    } catch (error) {
      console.error(error.message);
      toast(`Error adding custom keyword "${customKeywordInputValue}"`, {
        type: 'error',
      });
    }
  };
  const _updateAbbreviators = (volumes) => {
    try {
      let minVolumes = Math.min(...volumes);
      let maxVolumes = Math.max(...volumes);
      Object.values(abbreviators)
        .sort((a, b) => a.magnitude - b.magnitude)
        .some((a) => {
          // console.log({ a });
          if (a.magnitude * 1000 > minVolumes) {
            setVolumeAbbreviator(a);
            return true;
          }
        });
    } catch (error) {
      setVolumeAbbreviator(abbreviators.NONE);
    }
  };

  const handleSelectMonth = (monthIndex) => {
    setActiveMonthIndex(parseInt(monthIndex));
  };

  const sortKeywordsForVisibleMonthIndex = ({
    strategiesData,
    visibleMonthIndex,
  }) => {
    let newData = [...strategiesData];
    let monthData = newData[visibleMonthIndex];
    let k = (monthData.keywords || []).map((object, index) => {
      return { ...object, index };
    });

    if (_.isArray(k) && k.length > 0) {
      let n = [...k].sort((a, b) => {
        if (sortField.id === 'use') {
          if (_.isNil(a.use) && _.isNil(b.use)) {
            return 0;
          } else if (_.isNil(a.use)) {
            return sortDirection === 1 ? 1 : -1;
          } else if (_.isNil(b.use)) {
            return sortDirection === 1 ? -1 : 1;
          } else if (a.use === b.use) {
            return a.keyword > b.keyword ? 1 : -1;
          } else {
            return a.use
              ? sortDirection === 1
                ? -1
                : 1
              : sortDirection === 1
              ? 1
              : -1;
          }
        }
        if (a[sortField.id] < b[sortField.id]) {
          return sortDirection === 1 ? -1 : 1;
        }
        if (a[sortField.id] > b[sortField.id]) {
          return sortDirection === 1 ? 1 : -1;
        }
      });
      let nd = newData[visibleMonthIndex];
      nd = { ...nd, keywords: n };
      newData[visibleMonthIndex] = nd;
      // newData[visibleMonthIndex].keywords = n;
    }
    return newData;
  };

  const handleClickSort = (sfid) => {
    let sF = Object.values(dataFields).find((d) => d.id === sfid);
    if (sortField.id === sF.id) {
      setSortDirection(sortDirection === 0 ? 1 : 0);
    } else {
      setSortDirection(sF.defaultSortDirection === 0 ? 0 : 1);
      setSortField(sF);
    }
  };

  const renderIconForDateType = ({ type = 'scheduled' } = {}) => {
    switch (type) {
      default:
        return <></>;
      case 'scheduled':
        return <FaRegCalendarAlt size={20} />;
      case 'published':
        return <FaCheck size={20} />;
      case 'drafted':
        return <FaPenNib size={20} />;
    }
  };

  const updateDBValue = ({ month, index, use }) => {
    (async () => {
      try {
        const docRef = firebase
          .firestore()
          .collection(`strategy/${user.uid}/months`)
          .doc(month);

        let update = strategies.find((m) => m.id === month);
        let keywords = [...update.keywords].sort((a, b) => a.index - b.index);
        let keyword = keywords[index].keyword;
        keywords[index] = { ...keywords[index], use };

        /* In order to update this back correctly we need to
        sort by the previous index and then remove the index field */
        update.keywords = keywords.map(({ index, ...rest }) => rest);

        docRef.update(update);
        try {
          toast(
            `${_.startCase(_.toLower(keyword))} ${
              use ? 'added to ' : 'removed from '
            }keywords`,
            { type: 'success' }
          );
        } catch (error) {
          toast(`Keywords updated`, { type: 'success' });
        }
      } catch (error) {
        console.error(error.message);
        toast(`Error updating keywords`, { type: 'error' });
      }
    })();
  };
  const renderDateInHeader = ({ date = null, type = 'scheduled' } = {}) => (
    <>
      <div className="accordion-header-spacer"></div>
      <div className="accordion-header-date">
        {renderIconForDateType({ type })}
        {date.toDate().toLocaleString('en-US', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          month: 'short',
          day: 'numeric',
        })}
      </div>
    </>
  );
  return (
    <div className="strategy-content">
      {!strategies || !_.isArray(strategies) || strategies.length === 0 ? (
        <div>
          {`No strategies to show. Please make sure your subscription is active
          and you have entered your competitors in Settings > Business.`}
        </div>
      ) : (
        <>
          <Accordion
            defaultActiveKey={currentIndex.toString()}
            flush
            onSelect={handleSelectMonth}
          >
            {strategies.map((strategy, sIndex) => (
              <Accordion.Item
                key={`${sIndex}`}
                eventKey={sIndex.toString()}
                className={`strategy-months-accordion-item`}
              >
                <Accordion.Header>
                  <div>
                    <h1>
                      {strategy.active_at.toDate().toLocaleString('en-US', {
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                        month: 'short',
                        day: 'numeric',
                      })}
                      {sIndex === currentIndex && ` - Current`}
                    </h1>
                    <span style={{ marginRight: 6, fontWeight: 'bold' }}>
                      Keywords:
                    </span>
                    {(strategy.keywords || [])
                      .filter((k) => k.use === true)
                      .map((k) => _.startCase(_.toLower(k.keyword)))
                      .sort((a, b) => {
                        if (a < b) {
                          return -1;
                        }
                        if (a > b) {
                          return 1;
                        }
                        return 0;
                      })
                      .join(', ')}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="strategy-details">
                    <div className="labels">Competitors:</div>
                    <div className="values">{strategy.domains.join(', ')}</div>
                    <div className="labels" style={{ marginTop: 40 }}>
                      Generated Keywords to Target:
                    </div>
                    <div className="values">
                      <Formik
                        initialValues={{
                          keywords: (strategy.keywords || []).reduce(
                            (acc, obj, index) => {
                              if (obj.use === true) {
                                acc.push(index);
                              }
                              return acc;
                            },
                            []
                          ),
                        }}
                      >
                        {({
                          errors,
                          touched,
                          isSubmitting,
                          values,
                          setFieldValue,
                        }) => (
                          <Form>
                            <Table responsive striped>
                              <thead>
                                <tr>
                                  {Object.values(dataFields).map((dF, idf) => (
                                    <th
                                      key={`data-field-index-${idf}`}
                                      style={{
                                        textAlign: dF.align || 'right',
                                      }}
                                      className={`${
                                        dF.id === sortField.id
                                          ? `${
                                              sortDirection === 1
                                                ? 'sorted-down'
                                                : 'sorted-up'
                                            }`
                                          : ''
                                      }${
                                        dF.defaultSortDirection === 0
                                          ? ' default-sort-down'
                                          : ''
                                      }${
                                        dF.sortable === false ? ' no-sort' : ''
                                      }`}
                                      onClick={() => {
                                        if (dF.sortable !== false) {
                                          handleClickSort(dF.id);
                                        }
                                      }}
                                    >
                                      <div>
                                        {_.isFunction(dF.label)
                                          ? dF.label({ volumeAbbreviator })
                                          : dF.label}
                                      </div>
                                      {dF.sortable !== false && (
                                        <>
                                          <FaSortUp className="sort-up" />
                                          <FaSortDown className="sort-down" />
                                        </>
                                      )}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {(strategy.keywords || []).map(
                                  (keyword, kIndex) => (
                                    <tr key={`table-row-${kIndex}`}>
                                      {Object.values(dataFields).map(
                                        (dF, idf) => (
                                          <>
                                            {![
                                              dataFields.USE.id,
                                              dataFields.KEYWORD.id,
                                            ].includes(dF.id) &&
                                            isNaN(keyword.score) ? (
                                              <>
                                                {(dF.id || dF.fieldName) ===
                                                'score' ? (
                                                  <td
                                                    key={`data-fields-values-${kIndex}-${idf}`}
                                                    style={{
                                                      textAlign:
                                                        dF.align || 'right',
                                                      opacity: 0.5,
                                                      fontStyle: 'italic',
                                                    }}
                                                    colSpan={
                                                      Object.keys(dataFields)
                                                        .length - 2
                                                    }
                                                  >
                                                    Custom keyword
                                                  </td>
                                                ) : null}
                                              </>
                                            ) : (
                                              <td
                                                key={`data-fields-values-${kIndex}-${idf}`}
                                                style={{
                                                  textAlign:
                                                    dF.align || 'right',
                                                }}
                                              >
                                                {_.isFunction(dF.render) ? (
                                                  dF.render({
                                                    strategy,
                                                    keyword,
                                                    index: _.isNil(
                                                      keyword.index
                                                    )
                                                      ? kIndex
                                                      : keyword.index,
                                                    updateDBValue,
                                                    volumeAbbreviator,
                                                  })
                                                ) : (
                                                  <>
                                                    {
                                                      keyword[
                                                        dF.id || dF.fieldName
                                                      ]
                                                    }
                                                  </>
                                                )}
                                              </td>
                                            )}
                                          </>
                                        )
                                      )}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                            <Button
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => {
                                setShowAddCustomKeywordModal(true);
                              }}
                            >
                              <FaPlus style={{ marginRight: 6 }} />
                              Custom Keyword
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <Modal show={showAddCustomKeywordModal}>
            <ModalHeader>
              <ModalTitle>Add a Custom Keyword</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <BootstrapForm.Control
                name={'Keyword'}
                id={'keyword'}
                placeholder="Enter Keyword"
                value={customKeywordInputValue}
                onKeyDown={(k) => {
                  if (k.code === 'Enter') {
                    _handleAddCustomKeyword();
                  }
                }}
                onChange={(e) => {
                  setCustomKeywordInputValue(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              {keywordUploading ? (
                <FormLabel style={{ marginRight: 10 }}>Loading...</FormLabel>
              ) : (
                <>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      setCustomKeywordInputValue('');
                      setShowAddCustomKeywordModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={_handleAddCustomKeyword}>Add</Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    strategiesFromDB: Object.values(state.StrategyState || {}),
    user: state.UserState,
  };
};

export default connect(mapStateToProps)(AccountTabPostsStrategy);
