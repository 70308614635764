import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import { Redirect, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
  InputGroup,
  FloatingLabel,
  Row,
  Col,
  Card,
} from 'react-bootstrap';

import './signup.css';
import { auth, firebase } from '../index.js';
import { toast } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';

const Signup = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      return auth
        .createUserWithEmailAndPassword(values.email, values.password)
        .then((userCredential) => {
          const user = userCredential.user;
          try {
            (async (user, values) => {
              user
                .updateProfile({
                  displayName: values.name,
                })
                .then(() => {
                  // Update successful
                })
                .catch((error) => {
                  // Handle errors here
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.error(errorCode, errorMessage);
                });
            })(user, values);
            toast('Account created', { type: 'success' });
            try {
              const db = firebase.firestore();
              let userData = _.omit(values, ['password', 'confirmPassword']);
              userData.created_at =
                firebase.firestore.FieldValue.serverTimestamp();
              db.collection('users').doc(user.uid).set(userData);
              return true;
            } catch (error) {
              console.error(error.message);
              return true;
            }
          } catch (error) {
            console.error(error.message);
            toast('Error creating account', { type: 'failure' });
            return false;
          }
        })
        .catch((error) => {
          console.error(error.code, error.message);
          toast(
            'Error creating account: ' +
              error.message.replace('Firebase:', '').trim(),
            { type: 'error' }
          );
          return false;
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Must be 6 characters or more'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  useEffect(() => {
    if (!!user) {
      navigate('/account');
    }
  }, [user, loading, error]);

  const formFields = {
    name: {},
    email: { type: 'email' },
    password: { type: 'password', autoComplete: 'new-password' },
    confirmPassword: {
      type: 'password',
      autoComplete: 'new-password',
      label: 'Confirm Password',
    },
  };

  const FloatingLabelInput = ({ label, name, ...rest }) => (
    <FloatingLabel label={label}>
      <Field name={name} id={name} {...rest} as={BootstrapForm.Control} />
    </FloatingLabel>
  );

  const renderSignUpByEmail = () => (
    <div id="page-signup">
      <Container>
        <Row>
          <Col xs md={{ span: 4, offset: 4 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 10,
              }}
            >
              <div>Already have an account?</div>
              <a href="/login">Click to log in</a>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'center',
                gap: 2,
                margin: '10px 0',
              }}
            >
              <div
                style={{ height: 0, border: '1px solid grey', flex: 1 }}
              ></div>
              <div style={{ flex: 0 }}>OR</div>
              <div
                style={{ height: 0, border: '1px solid grey', flex: 1 }}
              ></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs md={{ span: 4, offset: 4 }}>
            <div>Complete the form to sign up</div>
            <Card>
              <Card.Body>
                <Formik
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    setSubmitting(true);
                    try {
                      (async (values, resetForm, setSubmitting) => {
                        setSubmitting(true);
                        let submitted = await handleSubmit(values);
                        setSubmitting(false);
                        resetForm();
                      })(values, resetForm, setSubmitting);
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={SignupSchema}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      {Object.entries(formFields).map(([key, value], index) => {
                        let name = value.name || key;
                        return (
                          <Row key={`${index}--${key}`} className="mb-3">
                            <BootstrapForm.Group as={Col}>
                              <FloatingLabelInput
                                label={
                                  value.label || _.startCase(_.toLower(name))
                                }
                                name={name}
                                type={value.type || 'text'}
                                placeholder={
                                  value.label || _.startCase(_.toLower(name))
                                }
                                autoComplete={value.autoComplete || name}
                                isInvalid={touched[name] && !!errors[name]}
                              />
                              {touched[name] && errors[name] && (
                                <BootstrapForm.Control.Feedback type="invalid">
                                  {errors[name]}
                                </BootstrapForm.Control.Feedback>
                              )}
                            </BootstrapForm.Group>
                          </Row>
                        );
                      })}
                      <Row className="mb-3">
                        <Col>
                          <Button type="submit" disabled={isSubmitting}>
                            Create Account
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

  return <>{renderSignUpByEmail()}</>;
};

const mapStateToProps = (state) => {
  return {
    success: state.UserState.status,
  };
};

Signup.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(Signup);
