import _ from 'lodash';
import { auth, db, firebase } from '..';
import {
  useDocumentData,
  useCollectionData,
  useCollection,
} from 'react-firebase-hooks/firestore';
import { collection, doc } from 'firebase/firestore';

export const clearNotifications = async ({ notificationFields, userId }) => {
  if (!(_.isArray(notificationFields) && notificationFields.length > 0)) {
    return;
  }
  const update = notificationFields.reduce(
    (acc, cur) => ({ ...acc, [cur]: firebase.firestore.FieldValue.delete() }),
    {}
  );

  await firebase.firestore().collection('users').doc(userId).update(update);
};
