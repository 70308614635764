import PropTypes from 'prop-types';
import React, { Component, useEffect, useState } from 'react';
import {
  FaDollarSign,
  FaExternaltoAlt,
  FaPowerOff,
  FaUserAlt,
  FaUserCircle,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {} from '../../actions/userActionCreator';
import { auth } from '../../index.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/nav.scss';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const Nav = ({ menu = 'public' }) => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();

  const renderDropdownParent = ({ text, to, options }) => (
    <div className="dropdown">
      <a
        href={to}
        className="dropdown-toggle dropdown-custom-cls"
        data-bs-toggle="dropdown"
      >
        {text}
      </a>
      {renderDropdown({ options })}
    </div>
  );

  const renderDropdown = ({ options = [] } = {}) => (
    <>
      {_.isArray(options) && options.length > 0 && (
        <div className="dropdown-menu">
          {options.map((o) => (
            <a href={o.to || '#'} className="dropdown-item">
              {o.text}
            </a>
          ))}
        </div>
      )}
    </>
  );

  const renderMenuItem = ({ text, to, onClick }) => (
    <li className="nav-item">
      <a href={to} onClick={onClick} className="nav-link">
        {text}
      </a>
    </li>
  );

  const renderAccountDropdown = () => (
    <div className="d-flex justify-content-end nav-custom-btn-cls">
      <div className="nav-account-button-wrapper">
        <a href="/account">
          <button type="button" className="btn btn-light custom-btn-cls">
            <FaUserCircle style={{ marginRight: 4 }} />
            {user.displayName}
          </button>
        </a>
        <a
          href="#"
          onClick={async () => {
            await auth.signOut();
            window.location.href = '/';
          }}
        >
          <button type="button" className="btn btn-light custom-btn-cls">
            Log Out
          </button>
        </a>
      </div>
    </div>
  );

  return (
    <>
      <a className="skip-link screen-reader-text" href="#content">
        Skip to content
      </a>

      <div
        data-elementor-type="header"
        data-elementor-id="9"
        className="elementor elementor-9 elementor-location-header"
      >
        <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
          <div className="container">
            <a href="/" className="navbar-brand">
              <img src="/images/logo.png" alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-start"
              tabIndex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  Back
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              {!!user ? (
                <>
                  {menu === 'public' ? (
                    <ul className="navbar-nav flex-grow-1 pe-3 d-flex justify-content-end align-items-center custom-nav-ul">
                      {renderMenuItem({ text: 'Home', to: '/' })}
                      {renderMenuItem({ text: 'About', to: '#' })}
                      {renderMenuItem({ text: 'Contact', to: '#' })}
                      {renderMenuItem({ text: 'My Account', to: '/account' })}
                      {renderAccountDropdown()}
                    </ul>
                  ) : (
                    <>{renderAccountDropdown()}</>
                  )}
                </>
              ) : (
                <div className="offcanvas-body">
                  <ul className="navbar-nav flex-grow-1 pe-3 d-flex justify-content-end align-items-center custom-nav-ul">
                    {renderMenuItem({ text: 'Home', to: '/' })}
                    {renderMenuItem({ text: 'About', to: '#' })}
                    {renderMenuItem({ text: 'Contact', to: '#' })}
                  </ul>
                  <div className="d-flex justify-content-center nav-custom-btn-cls">
                    <a href="/sign-up">
                      <button
                        type="button"
                        className="btn btn-light custom-btn-cls"
                      >
                        Create Account
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userState: state.UserState,
    initial: state,
  };
};

const mapDispatchToProps = {};

Nav.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  profileState: PropTypes.shape({
    profileData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
    errorStatus: PropTypes.string,
    profilePassErr: PropTypes.string,
    status: PropTypes.number,
  }),
  requestProfileData: PropTypes.func,
  requestVerifyProfileData: PropTypes.func,
  teamLength: PropTypes.number,
};
export default connect(mapStateToProps, mapDispatchToProps)(Nav);
