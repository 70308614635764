import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import { Link, Redirect, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
  InputGroup,
  FloatingLabel,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';

import { TABS } from '../../../AppTabs.js';
import { FaCircle } from 'react-icons/fa';
import { hasNotificationForUserAndPath } from '../../../utils/notificationHelpers.js';

const AccountTabPosts = ({ children, currentTab, user } = {}) => {
  return (
    <>
      <ListGroup horizontal className="mb-3">
        {Object.values(TABS.POSTS.TABS).map((tab, index) => (
          <ListGroupItem
            key={`tab--${tab.label}--${index}`}
            className={tab.label == currentTab ? 'active' : ''}
          >
            <Link to={tab.path}>{tab.label}</Link>
            {hasNotificationForUserAndPath({
              user,
              path: `${_.snakeCase(`${TABS.POSTS.label} ${tab.label}`)}`,
            }) && <FaCircle className="notification" color="#dd3448" />}
          </ListGroupItem>
        ))}
      </ListGroup>
      {children}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

AccountTabPosts.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(AccountTabPosts);
