import produce from 'immer';

import { SET_USER } from './userConstants';

const INITIAL_STATE = {
  competitor_domains: null,
  created_at: null,
  email: null,
  name: null,
  id: null,
  post_days: null,
  posts_per_wk: null,
  posts_products_you_sell: null,
  posts_style: null,
  posts_target_audience: '',
  uid: null,
};

export const userReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_USER:
        return {
          ...action.payload,
        };

      default:
        return state;
    }
  });
