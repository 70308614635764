import React from 'react';
import { GiHighGrass } from 'react-icons/gi';
import { MdRoofing } from 'react-icons/md';
import { AiFillCode } from 'react-icons/ai';

const SectionSampleContent = () => (
  <section className="section-wrapper" id="post-wrapper">
    <div>
      <h2 className="h2-custom-cls">Sample Content</h2>
    </div>
    <div className="u-blog post-Container container">
      <div className="u-blog-post u-repeater-item u-white u-repeater-item-1">
        <div className="post-box">
          <div className="post-img-col-1">
            <GiHighGrass color="#ffffff" size={30} />
            <img
              alt=""
              className="post-img"
              src="/img/landscaping-sample.jpg"
            />
          </div>
          <div className="container-layout-post">
            <div className="blog-metadata">
              <span className="post-meta-date">JULY 24, 2023</span>
            </div>

            <h2 className="blog-heading">
              <a className="u-post-header-link" href="/samples/austin-law-guys">
                Austin Lawn Guys
              </a>
            </h2>

            <div className="post-content Post-text">
              <p>
                Discover how The Austin Lawn Guys, a premier landscaping and
                lawn care service in Austin, TX, can transform your outdoor
                space. From professional lawn mowing...
              </p>
            </div>
            <div className="footer-btn">
              <a href="/samples/austin-law-guys">Read More</a>
            </div>
          </div>
        </div>

        <div className="post-box">
          <div className="post-img-col-2">
            <MdRoofing color="#ffffff" size={30} />
            <img alt="" className="post-img" src="/img/roofing-sample.jpg" />
          </div>
          <div className="container-layout-post">
            <div className="blog-metadata">
              <span className="post-meta-date">JULY 9, 2023</span>
            </div>

            <h2 className="blog-heading">
              <a
                className="u-post-header-link"
                href="/samples/wright-and-co-roofing"
              >
                Wright & Co. Roofing
              </a>
            </h2>

            <div className="post-content Post-text">
              <p>
                In this blog, we explore the roofing options available for
                homeowners in Reston. Wright & Co Roofing, a family-owned
                contractor with over 65 years of experience...
              </p>
            </div>
            <div className="footer-btn">
              <a href="/samples/wright-and-co-roofing">Read More</a>
            </div>
          </div>
        </div>
        <div className="post-box">
          <div className="post-img-col-3">
            <AiFillCode color="#ffffff" size={30} />
            <img alt="" className="post-img" src="/img/software-sample.jpg" />
          </div>
          <div className="container-layout-post">
            <div className="blog-metadata">
              <span className="post-meta-date">JUNE 28, 2023</span>
            </div>

            <h2 className="blog-heading">
              <a className="u-post-header-link" href="/samples/innosoft">
                InnoSoft
              </a>
            </h2>

            <div className="post-content Post-text">
              <p>
                In this blog, we delve into the world of software development,
                exploring how InnoSoft, a cutting-edge development agency,
                utilizes AWS, Firebase, Google Analytics...
              </p>
            </div>
            <div className="footer-btn">
              <a href="/samples/innosoft">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default SectionSampleContent;
