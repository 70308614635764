import { getAnalytics } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { getFirestore } from 'firebase/firestore';

import React from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

const container = document.getElementById('root');

let app;

if (process.env.NODE_ENV === 'development') {
  const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'localhost',
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  });

  // Use the Firestore emulator
  firebase.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  });
  firebase.auth().useEmulator('http://localhost:9099/');
  firebase.functions().useEmulator('localhost', 5001);
} else {
  app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  });
}

const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const firestore = firebase.firestore();

createRoot(container).render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
reportWebVitals();

export { db, auth, storage, functions, analytics, firestore, firebase };
export default firebase;
