import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';

import { collection } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { FaCheck, FaPenNib, FaRegCalendarAlt } from 'react-icons/fa';
import { firebase, functions } from '../../../index.js';

import { TABS } from '../../../AppTabs.js';
import { connect } from 'react-redux';
import { clearNotifications } from '../../../utils/firebaseHelpers.js';

const AccountTabPostsReview = ({ posts = [], user, type = 'past' } = {}) => {
  useEffect(() => {
    clearNotifications({
      notificationFields: ['notifications.posts_review'],
      userId: user.uid,
    });
    return () => {
      clearNotifications({
        notificationFields: ['notifications.posts_review'],
        userId: user.uid,
      });
    };
  }, []);

  const _handleClickPublishPost = ({ postId }) => {
    if (_.isString(postId) && postId.length > 0) {
      const publishDraftedPostCallable = functions.httpsCallable(
        'publishDraftedPostCallable'
      );
      try {
        (async () => {
          const result = await publishDraftedPostCallable({
            userId: user.uid,
            postId,
          });
        })();
      } catch (error) {
        console.error(error);
      }
    }
  };
  const renderIconForDateType = ({ type = 'scheduled' } = {}) => {
    switch (type) {
      default:
        return <></>;
      case 'scheduled':
        return <FaRegCalendarAlt size={20} />;
      case 'published':
        return <FaCheck size={20} />;
      case 'drafted':
        return <FaPenNib size={20} />;
    }
  };
  const renderDateInHeader = ({ date = null, type = 'scheduled' } = {}) => (
    <>
      <div className="accordion-header-spacer"></div>
      <div className="accordion-header-date">
        {renderIconForDateType({ type })}
        {date.toDate().toLocaleString('en-US', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          month: 'short',
          day: 'numeric',
        })}
      </div>
    </>
  );
  return (
    <div className="post-content">
      {!posts || !_.isArray(posts) || posts.length === 0 ? (
        <div>No posts to show</div>
      ) : (
        <Accordion defaultActiveKey="0" flush>
          {posts.map((post, index) => (
            <Accordion.Item
              eventKey={index}
              className={
                !!post.date_published
                  ? 'published'
                  : !!post.date_drafted
                  ? 'drafted'
                  : !!post.date_scheduled
                  ? 'scheduled'
                  : ''
              }
            >
              <Accordion.Header>
                <div>{post.title}</div>
                {!!post.date_published ? (
                  <>
                    {renderDateInHeader({
                      date: post.date_published,
                      type: 'published',
                    })}
                  </>
                ) : (
                  <>
                    {post.date_drafted && (
                      <>
                        {renderDateInHeader({
                          date: post.date_drafted,
                          type: 'drafted',
                        })}
                      </>
                    )}
                  </>
                )}
              </Accordion.Header>
              <Accordion.Body>
                {!!post.screenshot_url && (
                  <img className="post-screenshot" src={post.screenshot_url} />
                )}
                <div className="post-details">
                  <div className="labels">Title:</div>
                  <div className="values" style={{ fontWeight: 'bold' }}>
                    {post.title}
                  </div>
                  <div className="labels">Excerpt:</div>
                  <div
                    className="values"
                    style={{ marginBottom: 40, fontStyle: 'italic' }}
                  >
                    {post.excerpt}
                  </div>
                  <div className="labels">URL:</div>
                  <div className="values">
                    <a
                      href={post.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {post.url}
                    </a>
                  </div>
                  <div className="labels">URL to edit:</div>
                  <div className="values">
                    <a
                      href={post.url_admin}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {post.url_admin}
                    </a>
                  </div>
                  <div className="labels">Drafted:</div>
                  <div className="values">
                    {!!post.date_drafted && (
                      <>
                        {post.date_drafted.toDate().toLocaleString('en-US', {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        })}
                      </>
                    )}
                  </div>
                  <div className="labels">Published:</div>
                  <div className="values">
                    {!!post.date_published ? (
                      post.date_published.toDate().toLocaleString('en-US', {
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                      })
                    ) : (
                      <>
                        {!!post.date_drafted && (
                          <Button
                            style={{ fontWeight: 900 }}
                            onClick={() => {
                              _handleClickPublishPost({ postId: post.id });
                            }}
                          >
                            <FaCheck
                              size={20}
                              style={{ marginRight: 8 }}
                            ></FaCheck>
                            Click here to publish
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="labels"></div>
                  <div className="labels" style={{ marginTop: 40 }}>
                    Topic Details
                  </div>
                  <div className="labels">Targeted Search Terms:</div>
                  <div className="values">{post.keywords.join(', ')}</div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    posts: state.PostState,
    user: state.UserState,
  };
};

export default connect(mapStateToProps)(AccountTabPostsReview);
