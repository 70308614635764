import { SET_POST } from './postConstants';

/**
 * Set current user
 */
export function setPost(payload) {
  return {
    type: SET_POST,
    payload,
  };
}
