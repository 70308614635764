import React from 'react';
import '../../../styles/frontend.scss';
import moment from 'moment';

const industries = [
  'Hospitality',
  'Tourism',
  'Restaurants',
  'Cafes',
  'Real Estate',
  'Health',
  'Wellness',
  'Home Services',
  'Personal Finance',
  'Accounting',
  'Fashion',
  'Apparel',
  'Event Planning',
  'Childcare',
  'Education',
  'Automotive Services',
  'Pet Care',
  'Home Decor',
  'Interior Design',
  'Online Retail',
  'Technology Services',
  'Fitness',
  'Personal Training',
  'Art',
  'Crafts',
  'Legal Services',
  'Photography',
  'Environmental Services',
  'Beauty',
  'Cosmetics',
  'Travel Agencies',
  'Local Tours',
  'Guides',
  'Coffee Shops',
  'Bakeries',
  'Home Renovation',
  'Landscaping',
  'Gardening',
  'Wedding Services',
  'Online Education',
  'E-Learning',
  'Pet Supplies',
  'Accessories',
  'Jewelry',
  'Accessories',
  'Electronics',
  'Gadgets',
  'Fitness Equipment',
  'Gear',
  'Sports Coaching',
  'Training',
  'Arts',
  'Entertainment',
  'Music',
  'Instrument Stores',
  'Bookstores',
  'Publishing',
  'Language Schools',
  'Translation Services',
  'Social Media Marketing',
  'Graphic Design',
  'Branding',
  'Home-based Businesses',
  'Career Coaching',
  'Employment Services',
  'Health Food',
  'Nutrition',
  'Wine',
  'Liquor Stores',
  'Craft Breweries',
  'Distilleries',
  'Home Automation',
  'Smart Technology',
  'Eco-friendly Products',
  'E-commerce Platforms',
  'Solutions',
  'Mobile App Development',
  'Freelance Writing',
  'Content Creation',
  'Personal Development',
  'Coaching',
  'Handmade',
  'Artisanal Products',
  'Elderly Care',
  'Senior Services',
  'Language Learning',
  'Tutorials',
  'Online Marketing',
  'Advertising',
  'Personalized Gifts',
  'Keepsakes',
  'Fashion Styling',
  'Consulting',
  'Small Appliance Repair',
  'Second-hand',
  'Vintage Stores',
];
const getRandomDate = (minDaysAgo, maxDaysAgo, maxDate) => {
  const randomDays =
    Math.floor(Math.random() * (maxDaysAgo - minDaysAgo + 1)) + minDaysAgo;
  return moment(maxDate).subtract(randomDays, 'days').toDate();
};
const getRandomIntegersInRange = (X, Y) => {
  let result = [];

  while (result.length < 3) {
    const randomInt = Math.floor(Math.random() * (Y - X + 1)) + X;
    if (!result.includes(randomInt)) {
      result.push(randomInt);
    }
  }

  return result;
};
const SectionScroller = () => {
  const currentDate = moment().toDate();
  const firstDate = getRandomDate(1, 3, currentDate);
  const secondDate = getRandomDate(1, 3, firstDate);
  const thirdDate = getRandomDate(1, 3, secondDate);
  const industryIndex = getRandomIntegersInRange(0, industries.length);

  return (
    <section className="slider-section-cls d-flex align-items-center">
      <div id="owl-carousel" className="row owl-carousel">
        <h6 className="item slider-text-cls1">
          {`Recent Post: ${industries[industryIndex[0]]} on ${moment(
            firstDate
          ).format('MMM. Do')}`}
        </h6>
        <h6 className="item slider-text-cls2">
          {`Recent Post: ${industries[industryIndex[1]]} on ${moment(
            secondDate
          ).format('MMM. Do')}`}
        </h6>
        <h6 className="item slider-text-cls1">
          {`Recent Post: ${industries[industryIndex[2]]} on ${moment(
            secondDate
          ).format('MMM. Do')}`}
        </h6>
      </div>
    </section>
  );
};

export default SectionScroller;
