import { combineReducers } from 'redux';
import { userReducer } from './user/userReducer';
import { postReducer } from './post/postReducer';
import { strategyReducer } from './strategy/strategyReducer';
import { scheduleReducer } from './schedule/scheduleReducer';
import { subscriptionReducer } from './subscriptions/subscriptionsReducer';
import { productsReducer } from './products/productsReducer';

const reducers = combineReducers({
  UserState: userReducer,
  PostState: postReducer,
  StrategyState: strategyReducer,
  ScheduleState: scheduleReducer,
  SubscriptionState: subscriptionReducer,
  ProductsState: productsReducer,
});

export default reducers;
