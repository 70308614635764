import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
const AccountTabDashboard = ({ user }) => {
  return <>Under Construction. Coming soon.</>;
};

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

AccountTabDashboard.propTypes = {};

export default connect(mapStateToProps)(AccountTabDashboard);
