import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { Field, FieldArray, Formik, useFormik, Form } from 'formik';
import {
  Redirect,
  useLocation,
  useMatch,
  useNavigate,
  withRouter,
} from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
  InputGroup,
  FloatingLabel,
  Row,
  Col,
  Card,
} from 'react-bootstrap';

import './samples.scss';
import { auth, firebase } from '../index.js';
import { toast } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SAMPLE_CONTENT } from './SampleContent';

const Samples = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [sampleName, setSampleName] = useState(Object.keys(SAMPLE_CONTENT)[0]);

  // const match = useMatch();

  useEffect(() => {
    console.log(Object.keys(SAMPLE_CONTENT));
    let _sample = location.pathname.replace('/samples/', '');
    if (Object.keys(SAMPLE_CONTENT).includes(_sample)) {
      console.log('here');
      setSampleName(_sample);
    }
  }, [location]);

  return (
    <div id="page-sample">
      <div className="sample-container container">
        <div
          style={{
            padding: '60px 60px 120px',
            background: 'white',
            borderRadius: 60,
            marginTop: 40,
          }}
        >
          <div>Sample Content</div>
          <h1 style={{ textAlign: 'center', marginBottom: 40 }}>
            {SAMPLE_CONTENT[sampleName].title}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: SAMPLE_CONTENT[sampleName].content,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Samples;
