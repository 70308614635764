import produce from 'immer';

import { CLEAR_PRODUCTS, SET_CURRENT_PRODUCTS } from './productsConstants';

const INITIAL_STATE = [];

export const productsReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_PRODUCTS:
        return action.payload;

      case CLEAR_PRODUCTS:
        return INITIAL_STATE;

      default:
        return state;
    }
  });
