import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCheckDouble, FaTasks } from 'react-icons/fa';
import AccountTabSiteManualCreateAuthInstructionsList from './AccountTabSiteManualCreateAuthInstructionsList';

const AccountTabSiteAuthInstructions = ({
  setShowAutoCreateAuthModal,
  setShowManualCreateAuthModal,
}) => (
  <div className="account-subsection-full-width">
    <p>
      In order to publish to your site on your behalf, Paige needs to be given
      permissions to do so. Wordpress gives us a convenient way to do this that
      still gives you full privacy and admin access over any capabilities Paige
      will have. We take your privacy seriously, so please read the following:
    </p>
    <p>
      Paige needs a user with Editor permissions and an application password.
      This application password is a random string of characters that looks like
      this 'ABCD EFGH IJKL MNOP QRST UVWX'. Importantly this is not your
      Wordpress password. This allows you to revoke access at any time, giving
      you full authority over Paige. Additionally, with the application
      password, we don't need to ever store your actual password for your site.
      If you change your password at any time, it will not break Paige's
      connection with your site.
    </p>
    <p style={{ marginTop: 80 }}></p>
    <h3>Instructions:</h3>
    <AccountTabSiteManualCreateAuthInstructionsList />
    {/* <p>
      There are two ways to generate the authentication Paige needs: you can do
      it manually, or Paige can ask for your admin username and password one
      time and create it automatically for you. Paige will never store your
      username or password and will only use it once to create the access needed
      to post on your behalf.
    </p> */}
    <div
      className="authentication-setup-chooser-wrapper"
      style={{ gridTemplateColumns: '1fr' }}
    >
      {/* <div className="authentication-setup-automatic-wrapper">
        <Button
          onClick={() => {
            setShowAutoCreateAuthModal(true);
          }}
        >
          <FaCheckDouble size={40} />I would like Paige to automate this
        </Button>
      </div>
      <div className="authentication-setup-separator-wrapper">
        <div className="authentication-setup-separator"></div>
        <div className="authentication-setup-separator-text">OR</div>
        <div className="authentication-setup-separator"></div>
      </div>
      <div className="authentication-setup-automatic-wrapper">
        <Button
          onClick={() => {
            setShowManualCreateAuthModal(true);
          }}
        >
          <FaTasks size={40} />I am ready, show me instructions
        </Button>
      </div> */}
    </div>
  </div>
);

export default AccountTabSiteAuthInstructions;
