import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import _ from 'lodash';
import { removePrefixes } from '../../utils/strings';
import {
  Field,
  FieldArray,
  Formik,
  useFormik,
  Form,
  useFormikContext,
} from 'formik';
import { Redirect, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormLabel,
  Form as BootstrapForm,
  InputGroup,
  FloatingLabel,
  Row,
  Col,
  Card,
  FormGroup,
} from 'react-bootstrap';

import { FaPlus } from 'react-icons/fa';

const AccountTabCompetitionComponent = ({
  user,
  values,
  setFieldValue,
  handleBlur,
}) => (
  <>
    <FormLabel className="account-form-label">Competition</FormLabel>
    <FormGroup className="account-form-wrapper">
      <FieldArray name="competitor_domains">
        {({ push, remove }) => (
          <>
            {values.competitor_domains &&
              values.competitor_domains.map((customValue, index) => (
                <Row
                  key={index}
                  style={{ alignItems: 'flex-end', marginBottom: 12 }}
                >
                  <Col>
                    <BootstrapForm.Group
                      controlId={`competitor_domains.${index}`}
                    >
                      <Field
                        as={BootstrapForm.Control}
                        name={`competitor_domains.${index}`}
                        placeholder={`Enter competitor #${index + 1}'s domain`}
                        onBlur={(e) => {
                          let cValues = [...values.competitor_domains];
                          let entry = removePrefixes(e.target.value, [
                            'https://',
                            'http://',
                          ]);
                          if (entry !== e.target.value) {
                            setFieldValue(`competitor_domains.${index}`, entry);
                          }
                          cValues[index] = entry;
                          handleBlur({
                            value: cValues,
                            prevValue: user.competitor_domains,
                            name: 'competitor_domains',
                          });
                        }}
                      />
                    </BootstrapForm.Group>
                  </Col>
                  <Col md="auto">
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => {
                        let cValues = [...values.competitor_domains];
                        remove(index);
                        cValues.splice(index, 1);
                        handleBlur({
                          value: cValues,
                          prevValue: user.competitor_domains,
                          name: 'competitor_domains',
                          index,
                        });
                      }}
                    >
                      -
                    </Button>
                  </Col>
                </Row>
              ))}
            {(values.competitor_domains || []).length < 3 && (
              <Button variant="primary" type="button" onClick={() => push('')}>
                <FaPlus style={{ marginRight: 5 }} />
                Click to add competitor
              </Button>
            )}
          </>
        )}
      </FieldArray>
    </FormGroup>
  </>
);

const mapStateToProps = (state) => {
  return {
    user: state.UserState,
  };
};

AccountTabCompetitionComponent.propTypes = {};

export default connect(mapStateToProps)(AccountTabCompetitionComponent);
