import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Accordion,
  Table,
  Form as BootstrapForm,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  InputGroup,
  FloatingLabel,
  FormLabel,
} from 'react-bootstrap';
import { FaSpinner, FaSyncAlt } from 'react-icons/fa';

function ConfirmModal({
  show,
  onClose,
  title,
  message,
  onClickCancel,
  onClickConfirm,
  cancelButtonText,
  okButtonText,
  cancelButtonColor,
  okButtonColor,
  loading,
  loadingMessage,
  ...rest
}) {
  const isMounted = useRef(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal show={show} backdrop={true} scrollable={false} onClose={onClose}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>

      <ModalBody>{message}</ModalBody>

      <ModalFooter>
        {loading === true ? (
          <>
            {loadingMessage}
            <FaSyncAlt
              style={{ marginRight: 4, marginTop: -3 }}
              className="spinning-icon"
            />
          </>
        ) : (
          <>
            <Button
              variant={`outline-${cancelButtonColor || 'danger'}`}
              disabled={submitting}
              onClick={onClickCancel}
            >
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button
              variant={okButtonColor || 'primary'}
              disabled={submitting}
              className="mr-2"
              onClick={onClickConfirm}
            >
              {okButtonText || 'OK'}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default React.memo(ConfirmModal);
