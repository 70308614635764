import React from 'react';
import _ from 'lodash';
import { Field } from 'formik';
import { FormLabel, Form as BootstrapForm } from 'react-bootstrap';

const AccountTabFormLabelAndInput = ({
  name,
  index,
  value,
  handleBlur,
  prevValue,
  placeholder,
  touched,
  errors,
}) => (
  <React.Fragment key={`input-field-${name}-${index}`}>
    <FormLabel className="account-form-label">
      {value.label || _.startCase(_.toLower((name || '').replaceAll('_', ' ')))}
    </FormLabel>
    <BootstrapForm.Group className="account-form-wrapper">
      <Field
        label={
          value.label ||
          _.startCase(_.toLower((name || '').replaceAll('_', ' ')))
        }
        name={name}
        type={value.type || 'text'}
        placeholder={placeholder}
        autoComplete={value.autoComplete || name}
        isInvalid={touched[name] && !!errors[name]}
      >
        {({ field }) => (
          <input
            {...field}
            className="form-control"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                handleBlur({
                  value: e.target.value,
                  prevValue,
                  name,
                });
              }
            }}
            onBlur={(e) => {
              handleBlur({
                value: e.target.value,
                prevValue,
                name,
              });
            }}
          />
        )}
      </Field>
      {touched[name] && errors[name] && (
        <BootstrapForm.Control.Feedback type="invalid">
          {errors[name]}
        </BootstrapForm.Control.Feedback>
      )}
    </BootstrapForm.Group>
  </React.Fragment>
);

export default AccountTabFormLabelAndInput;
