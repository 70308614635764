import _ from 'lodash';

export const hasNotificationForUserAndPath = ({ user, path }) => {
  if (
    !(_.isString(path) && path.length > 0 && _.isObject(user.notifications))
  ) {
    return false;
  }
  for (const key in user.notifications) {
    if (path.endsWith('*')) {
      if (path.length > 1) {
        if (
          key.startsWith(path.slice(0, -1)) &&
          user.notifications[key] === true
        ) {
          return true;
        }
      } else {
        if (key.startsWith('post') && user.notifications[key] === true) {
          return true;
        }
      }
    } else if (key === path && user.notifications[key] === true) {
      return true;
    }
  }
  return false;
};
