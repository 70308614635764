export const removePrefixes = (str, prefixes) => {
  if (typeof prefixes === 'string') {
    prefixes = [prefixes];
  }
  for (let i = 0; i < prefixes.length; i++) {
    if (str.startsWith(prefixes[i])) {
      str = str.slice(prefixes[i].length);
    }
  }
  return str;
};
