import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firebase } from '../index.js';
import { toast } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';

const Logout = () => {
  let navigate = useNavigate();

  useEffect(() => {
    try {
      auth.signOut();
      toast('Signed out', { type: 'success' });
    } catch (error) {
      toast('Error signing out', { type: 'success' });
    }
    navigate('/login');
  }, []);

  return <></>;
};

export default Logout;
