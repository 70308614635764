import React from 'react';
const SectionHowItWorks = () => (
  <section className="custom-bg-works">
    <div className="container">
      <div className="row my-5 work-section justify-content-center ">
        <h2 className="text-center h2-custom-cls mt-5 mb-5 ">How It Works</h2>
        <div className="col-md-4 col-sm-12 mt-5">
          <div className="card text-center card-1">
            <div className="card-body">
              <h5 className="card-title">Step 1</h5>
              <p style={{ marginBottom: 30 }} className="card-text">
                Pick a plan that suits your needs.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-12 mt-5">
          <div className="card text-center card-2">
            <div className="card-body">
              <h5 className="card-title">Step 2</h5>
              <p style={{ marginBottom: 30 }} className="card-text">
                Provide some info about your business.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-12 mt-5">
          <div className="card text-center card-3">
            <div className="card-body">
              <h5 className="card-title">Step 3</h5>
              <p style={{ marginBottom: 30 }} className="card-text">
                That’s it. Paige handles the rest.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default SectionHowItWorks;
