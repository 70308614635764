import React from 'react';
const SectionWhoCanUse = () => (
  <section className="my-5 custom-bg-use">
    <div className="container">
      <div className="row my-5 justify-content-center row-use-paige">
        <h2 className="text-center h2-custom-cls">
          Who can use <span> Paige</span>?
        </h2>
        <div className="col-md-9 ">
          <p className="p-cls-1 mx-4">
            From small businesses, to marketing teams, to agencies and more,
            Paige will boost your SEO efforts by making sure you are posting the
            right content, at the right times. Choose our daily post package and
            see optimized content at scale like you have never seen.
          </p>
        </div>

        <div className="col-md-6 mt-5">
          <h4 className="custom-h4-cls mx-5">Have You</h4>
          <ul className="use-paige-ul-cls mt-4 mx-5">
            <li>Wanted to start a blog but never got to it?</li>
            <li>Started a blog and abandoned it after a few posts?</li>
            <li>
              Spent money on SEO and had no way to track the success of the
              campaign (or even know what the SEO firm did)?
            </li>
            <li>
              Wished you could spend more time on what you're good at and not
              worry about blog content?
            </li>
          </ul>
          <div className="paige-btn-div mx-5">
            <button type="button" className="btn btn-light my-5 use-paige-btn">
              Let Paige Help
            </button>
          </div>
        </div>
        <div className="col-md-3 mt-5">
          <div className="text-center">
            <img
              className="robofly img-fluid"
              src="/images/Isolation-Mode.png"
              alt=""
            />
            <div className="bg-blue">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default SectionWhoCanUse;
