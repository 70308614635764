import { SET_STRATEGY } from './strategyConstants';

/**
 * Set current user
 */
export function setStrategy(payload) {
  return {
    type: SET_STRATEGY,
    payload,
  };
}
